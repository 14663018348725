import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import StorageIcon from '@mui/icons-material/Storage';
import DownloadIcon from '@mui/icons-material/Download';
import { useCSVReader } from 'react-papaparse';
import { newImportAdminsColumnFormat } from '../../utils/importAdminsColumsFormat';
import { base_url } from '../Mode';
import axiosInstance from 'apis/axiosInstance';
import constants from 'utils/constants';

const AdminBulkImportModal = ({
  open,
  onChange,
  setIsSuccess,
  setHasError,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const { CSVReader } = useCSVReader();
  const [columnsInCSVInput, setColumnsInCSVInput] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [unRecognizedColumns, setUnRecognizedColumns] = useState('');
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const [hasImported, setHasImported] = useState(false);

  const handleVerifyInputColumns = () => {
    setLoading(true);
    const knownColumns = new Set(newImportAdminsColumnFormat);
    const unknownColumns = columnsInCSVInput.filter((column) => {
      if (!knownColumns.has(column)) return column;
    });
    setUnRecognizedColumns(unknownColumns.join(','));
    if (unknownColumns.length > 0) {
      setHasError(true);
      setErrorMessage(
        `There are unrecognized colums: ${unknownColumns.join(
          ','
        )} please remove them, Accepted columns are ${newImportAdminsColumnFormat.join(
          ','
        )} `
      );
      setLoading(false);
      resetFilters();
      return;
    }
    handleAdminsBulkImport();
  };

  const convertCsvToJson = (csvData) => {
    csvData.pop();
    return csvData.map((values) =>
      columnsInCSVInput.reduce((obj, key, index) => {
        obj[key] = values[index];
        return obj;
      }, {})
    );
  };

  const handleAdminsBulkImport = () => {
    setServerResponse({});
    const data = convertCsvToJson(csvData);

    if (data.length === 0) {
      setHasError(true);
      setErrorMessage('No data to import');
      resetFilters();
      return;
    }

    axiosInstance
      .post(`/api/admins/import`, {
        data,
        columns: columnsInCSVInput,
      })
      .then((res) => {
        setServerResponse(res.data);
        setLoading(false);
        if (res.data.successAdminsEntries.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('Admins have been added successfully');
        } else {
          setHasError(true);
          setErrorMessage('could not create one or more admins');
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(err.error || 'Somthing went wrong');
        setLoading(false);
      })
      .finally(() => {
        resetFilters();
      });
  };

  const downloadFailedEntriesCSV = (failedEntries) => {
    if (failedEntries.length === 0) {
      return;
    }

    const headers = ['error', ...newImportAdminsColumnFormat];

    const csvData = failedEntries.map((entry) => {
      const rowData = [
        entry.err,
        ...newImportAdminsColumnFormat.map((header) => entry.row[header] ?? ''),
      ];
      return rowData.join(',');
    });

    const csvContent = [headers.join(','), ...csvData].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'failed_entries.csv';
    link.click();
  };

  const downloadTestCSV = (data, filename) => {
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      data.map((row) => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
  };

  const testcsvfileData = [
    ['fname', 'lname', 'email', 'role', 'manager_email', 'product'],
    [
      'Vikash',
      'Kumar',
      'vikash@gmail.com',
      'trainee',
      'deepak@gmail.com',
      constants.SUPER30,
    ],
    [
      'Nitesh',
      'Kumar',
      'nitesh@gmail.com',
      'independent_contributer',
      'deepanshu@gmail.com',
      constants.SUPER30,
    ],
    [
      'aman',
      'Kumar',
      'aman@gmail.com',
      'independent_contributer',
      'sohel@gmail.com',
      constants.SUPER30,
    ],
  ];

  const resetFilters = () => {
    setColumnsInCSVInput([]);
    setCsvData([]);
    setUnRecognizedColumns('');
    setLoading(false);
    setHasImported(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onChange}
      maxWidth
    >
      <DialogTitle
        sx={{
          minWidth: '36rem',
          position: 'relative',
        }}
      >
        {'Import admins'}
        <IconButton
          onClick={onChange}
          sx={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please choose a CSV file</DialogContentText>
        <Box>
          <CSVReader
            onUploadAccepted={(result) => {
              setColumnsInCSVInput(result.data.shift());
              setCsvData(result.data);
              setHasImported(true);
              setServerResponse({});
            }}
          >
            {({ getRootProps, acceptedFile, ProgressBar }) => (
              <Stack
                gap={3}
                mt={2}
              >
                {acceptedFile && hasImported && (
                  <Typography>
                    <strong>Selected file name:</strong> {acceptedFile.name}
                  </Typography>
                )}
                {serverResponse && acceptedFile && (
                  <Stack
                    direction='row'
                    gap={5}
                  >
                    <Typography>
                      <strong>No of Success :</strong>{' '}
                      {serverResponse.successAdminsEntries?.length ?? 0}
                    </Typography>{' '}
                    <Typography>
                      <strong>No of failed :</strong>
                      {serverResponse.failedAdminsEntries?.length ?? 0}
                    </Typography>
                  </Stack>
                )}
                <ProgressBar />
                <Stack
                  direction={'row-reverse'}
                  gap={2}
                >
                  <Button
                    variant='contained'
                    startIcon={<FileUploadIcon size={15} />}
                    disabled={loading}
                    {...getRootProps()}
                  >
                    Select file
                  </Button>
                  {!acceptedFile && !hasImported && (
                    <>
                      <Button
                        variant='contained'
                        startIcon={<DownloadIcon size={15} />}
                        onClick={() =>
                          downloadTestCSV(testcsvfileData, 'example.csv')
                        }
                      >
                        test file
                      </Button>
                    </>
                  )}
                  {acceptedFile && hasImported && (
                    <>
                      <Button
                        variant='contained'
                        startIcon={
                          loading ? (
                            <CircularProgress size={15} />
                          ) : (
                            <StorageIcon size={15} />
                          )
                        }
                        disabled={loading}
                        onClick={handleVerifyInputColumns}
                      >
                        upload
                      </Button>
                    </>
                  )}
                  {serverResponse?.failedAdminsEntries?.length && (
                    <Button
                      variant='contained'
                      disabled={
                        serverResponse?.failedAdminsEntries?.length <= 0
                          ? true
                          : false
                      }
                      startIcon={<DownloadIcon size={15} />}
                      onClick={() =>
                        downloadFailedEntriesCSV(
                          serverResponse.failedAdminsEntries
                        )
                      }
                    >
                      download
                    </Button>
                  )}
                </Stack>
              </Stack>
            )}
          </CSVReader>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AdminBulkImportModal;
