import React, { useEffect, useState } from 'react';
import { Backdrop, Box } from '@mui/material';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import TabExistsDialog from './TabExistsDialogModal';
import constant from '../../utils/constants';
import axiosInstance from 'apis/axiosInstance';
import { setToken } from 'features/auth/authSlice';

const TabManagement = ({ showTab, setShowTabManagement }) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = async () => {
    try {
      const tab_id = sessionStorage.getItem(
        constant.LOCAL_STORAGE_UNIQUE_TAB_KEY
      );
      setDialogOpen(false);
      setToken(null);
      if (tab_id) {
        await axiosInstance.post(`${base_url()}/api/remove-id-from-redis`, {
          tab_id,
        });
      }
      window.location.replace('about:blank');
      alert('You can now close this tab.');
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message || 'Could not handle tab close.'
      );
    }
  };

  const handleLoadHere = async () => {
    try {
      const tab_id = sessionStorage.getItem(
        constant.LOCAL_STORAGE_UNIQUE_TAB_KEY
      );
      await axiosInstance.post(`${base_url()}/api/logout-other-tabs`, {
        newSocketId: tab_id,
      });
      setShowTabManagement(false);
      setDialogOpen(false);
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || 'Could not handle tab.');
    }
  };

  // 🚀 Handle Tab Close
  useEffect(() => {
    const handleTabClose = async () => {
      try {
        const tab_id = sessionStorage.getItem(
          constant.LOCAL_STORAGE_UNIQUE_TAB_KEY
        );
        if (tab_id) {
          axiosInstance.post(`${base_url()}/api/delete-redis-value`, {
            tab_id,
          });
        }
      } catch (err) {
        console.error(
          'Error during tab close:',
          err.response?.data || err.message
        );
      }
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {showTab() && (
        <>
          <Backdrop
            open={showTab()}
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              background: 'rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(10px)',
              zIndex: (theme) => theme.zIndex.modal + 1,
            }}
          ></Backdrop>

          <Box
            sx={{
              zIndex: (theme) => theme.zIndex.modal + 2,
            }}
          >
            <TabExistsDialog
              open={showTab()}
              onClose={handleClose}
              onLoadHere={handleLoadHere}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default TabManagement;
