import React, { useState } from 'react';
import {
  Box,
  Snackbar,
  Alert,
  ButtonGroup,
  Button,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import EditIcon from '@mui/icons-material/Edit';
import { splitByUnderscoresAndCapitalize } from 'utils/common';

const FeatureTable = ({
  data,
  setData,
  setIsSuccess,
  setSuccessMessage,
  loading,
  setLoading,
  setSelectedFeature,
  setOpenFullScreenDialog,
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [editableRows, setEditableRows] = useState({});
  const [editableRolloutUser, setEditableRolloutUser] = useState({});
  const [isEditIconClicked, setIsEditIconClicked] = useState(false);

  const handleInputChange = (row, newValue) => {
    const updatedRows = data.map((item) =>
      item.feature_id === row.feature_id
        ? { ...item, active_users: newValue }
        : item
    );
    setData(updatedRows);
  };

  const toggleEdit = (rowId) => {
    setEditableRolloutUser((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const saveRow = async (row) => {
    const updatedRow = data.find((item) => item.feature_id === row.feature_id);

    // Check if active_users is greater than total_users
    if (updatedRow.active_users > updatedRow.total_users) {
      setHasError(true);
      setErrorMessage('Rollout users cannot exceed Total users.');
      setOpenErrorSnackbar(true);
      return;
    }

    // Make the API call
    try {
      const response = await axiosInstance.patch(
        `/api/v1/access-table/${row.feature_id}/features`,
        { data: { rollout_percent: Number(updatedRow.active_users) } }
      );
      // Handle successful response
      if (response.status === 200) {
        setIsSuccess(true);
        setSuccessMessage('Rollout users updated successfully.');
        toggleEdit(row.feature_id);
      }
    } catch (error) {
      console.error('Error updating rollout users:', error);
      setHasError(true);
      setErrorMessage('Rollout users value cannot be lessar than 0.');
      setOpenErrorSnackbar(true);
    }
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    setIsEditIconClicked(true);
    setSelectedFeature(row);
    setOpenFullScreenDialog(true);
  };

  const handleRowClick = (params) => {
    if (params.field === 'active_users') {
      const rowId = params.row.feature_id;
      setEditableRolloutUser((prev) => ({
        ...prev,
        [rowId]: true,
      }));
    }
  };

  const updateFeatureStatus = async (row, status) => {
    try {
      setLoading(row.feature_id);
      const response = await axiosInstance.patch(
        `/api/v1/access-table/${row.feature_id}/features`,
        { data: { status } }
      );

      if (response.status === 200) {
        setIsSuccess(true);
        setSuccessMessage(`Feature status updated to ${status}`);
        const updatedData = data.map((item) =>
          item.feature_id === row.feature_id ? { ...item, status } : item
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
      setErrorMessage('Error updating feature status');
      // setOpenErrorSnackbar(true);

      const revertedRows = data.map((item) =>
        item.feature_id === row.feature_id
          ? { ...item, status: row.status }
          : item
      );
      setData(revertedRows);
    } finally {
      setLoading(null);
    }
  };

  const columns = [
    {
      field: 'feature_id',
      headerName: 'S.No',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
    },
    {
      field: 'feature_name',
      headerName: 'Name',
      width: 400,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        params.row?.feature_name
          ? splitByUnderscoresAndCapitalize(params.row.feature_name)
          : '-',
    },
    {
      field: 'active_users',
      headerName: 'Rollout Users',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const isEditableRollout = editableRolloutUser[params.row.feature_id];
        // const isEditable = isEditableRollout && isEditIconClicked;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isEditableRollout ? (
              <>
                <input
                  type='number'
                  value={params.row.active_users || ''}
                  onChange={(e) =>
                    handleInputChange(params.row, e.target.value)
                  }
                  style={{ width: '80px', padding: '4px' }}
                />
                <CheckCircleIcon
                  sx={{ color: 'green', cursor: 'pointer' }}
                  onClick={() => saveRow(params.row)}
                />
              </>
            ) : (
              <span>{params.row.active_users}</span>
            )}
          </Box>
        );
      },
    },
    {
      field: 'total_users',
      headerName: 'Total Users',
      width: 200,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'states',
      headerName: 'Status',
      width: 230,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const states = ['active', 'inactive'];
        return (
          <ButtonGroup
            color='primary'
            size='small'
          >
            {states.map((state) => (
              <Button
                key={state}
                disabled={loading}
                variant={params.row.status === state ? 'contained' : 'outlined'}
                onClick={() => updateFeatureStatus(params.row, state)}
              >
                {state}
              </Button>
            ))}
          </ButtonGroup>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          onClick={(event) => handleEditClick(event, params.row)}
          color='primary'
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box
      width='inherit'
      height='100%'
      sx={{
        mt: 5,
      }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.feature_id}
        pagination
        autoHeight
        disableSelectionOnClick
        onCellClick={handleRowClick}
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  );
};

export default FeatureTable;
