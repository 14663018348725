import { Box, Button, Dialog, Stack, TextField } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { WorkLogModal } from '../../../pages/Reports/WorkLog/WorkLogModal';
import WorkLogCalendarView from '../../../pages/Reports/WorkLog/WorkLogCalendarView';
import {
  getCurrentAdminId,
  getTimeZoneFormattedTimestamp,
  shouldEnforceFocusMode,
} from '../../../utils/common';
import { useCurrentLead } from '../../CurrentLeadContext';
import Logout from '../../home/Logout';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import FocusModeScreen from './FocusModeScreen';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function FocusModeDialog() {
  const { leadFocusMode } = useCurrentLead();
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openWorkLogCalendarModal, setOpenWorkLogCalendarModal] =
    useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [clickedDate, setClickedDate] = useState(
    getTimeZoneFormattedTimestamp(new Date()).split('T')[0]
  );
  const [selectedReport, setSelectedReport] = useState({});

  useEffect(() => {
    if (openReportModal) {
      fetchReport();
    }
    return () => {};
  }, [openReportModal]);

  const handleClose = () => {
    setOpenReportModal(false);
  };

  const handleOpenWorkLogCalendar = () => {
    setOpenWorkLogCalendarModal(true);
  };

  const handleCloseWorkLogCalendar = () => {
    setOpenWorkLogCalendarModal(false);
  };

  const fetchReport = () => {
    const queryParams = [
      `date=${clickedDate}`,
      `admin_id=${getCurrentAdminId()}`,
    ];

    axiosInstance
      .get(`/api/admins/reports?${queryParams.join('&')}`)
      .then((res) => {
        const { report = {} } = res.data;
        setSelectedReport(report);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while fetching report.'
        );
      });
  };

  return (
    <>
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Dialog
        open={leadFocusMode}
        fullScreen
        sx={{
          '& .MuiDialogContent-root': {
            overflowY: 'hidden',
            height: '100%',
            padding: '0px',
          },
          '& .MuiDialog-container': {
            padding: '40px 120px',
            backgroundColor: '#3cb371',
            position: 'relative',
          },
          '& .MuiPaper-root': {
            borderRadius: '10px',
          },
        }}
      >
        {shouldEnforceFocusMode() && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-end'
            gap='2px'
            sx={{
              position: 'fixed',
              top: '5px',
              right: '10px',
              color: '#fff',
            }}
          >
            <Button
              variant='text'
              onClick={(e) => handleOpenWorkLogCalendar(true)}
              sx={{
                color: 'white',
              }}
              size='small'
            >
              Work Log
            </Button>

            <Button
              variant='text'
              onClick={(e) => setOpenReportModal(true)}
              sx={{
                color: 'white',
              }}
              size='small'
            >
              Upload report
            </Button>
            <Logout />
          </Box>
        )}
        <FocusModeScreen />

        {openReportModal && (
          <WorkLogModal
            {...{
              openWorkLog: openReportModal,
              clickedDate,
              setIsSuccess,
              setHasError,
              setErrorMessage,
              setSuccessMessage,
              selectedReport,
              handleClose,
            }}
          >
            <Stack
              direction='row'
              justifyContent='flex-end'
            >
              <TextField
                type='date'
                label='date'
                size='small'
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setClickedDate(e.target.value)}
                value={clickedDate}
              />
            </Stack>
          </WorkLogModal>
        )}
      </Dialog>

      {/* WorkLogCalendarView Dialog */}
      {shouldEnforceFocusMode() && (
        <Dialog
          open={openWorkLogCalendarModal}
          fullWidth
          maxWidth='lg'
        >
          {/* Close Button */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton
              aria-label='close'
              size='small'
              sx={{
                m: 0.5,
                color: 'primary',
              }}
              onClick={handleCloseWorkLogCalendar}
            >
              <CloseIcon sx={{ fontSize: '40px' }} />
            </IconButton>
          </Box>

          {openWorkLogCalendarModal && <WorkLogCalendarView />}
        </Dialog>
      )}
    </>
  );
}

export default FocusModeDialog;
