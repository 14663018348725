import React, { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import { base_url } from '../../components/Mode';
import { splitByUnderscoresAndCapitalize } from '../../utils/common';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeadsInflowGraph from './LeadsInflowGraph';
import { allShortcuts } from './SingleClickShortcuts';
import Summary from './Summary';
import { Box } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LeadFunnel from '../../components/lead-funnel/LeadFunnel';
import { getDateRangeForLastNDays } from './SingleClickShortcuts';

const initialState = {
  from: getDateRangeForLastNDays(0).from,
  to: getDateRangeForLastNDays(0).to,
  status: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_region: '',
  utm_campaign_type: '',
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const shortCutArray = Object.keys(allShortcuts);

export default function MarketingDashboard() {
  const [filters, setFilters] = useState(initialState);
  const [marketingData, setMarketingData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expanded, setExpanded] = useState(true);
  const [shortcutFilter, setShortcutFilter] = useState(shortCutArray[0]);
  const [summaryData, setSummaryData] = useState({});
  const [expandedSummary, setExpandedSummary] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [isloading, setLoading] = useState(false);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };
  function handleResetFilters() {
    setFilters(initialState);
    setShortcutFilter(shortCutArray[0]);
  }

  useEffect(() => {
    axiosInstance
      .get(`/api/users/all_statuses`)
      .then((res) => {
        if (res.data) {
          setStatuses(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch all the statues'
        );
      });
    return () => {};
  }, []);

  useEffect(() => {
    fetchMarketingInsights();
    return () => {};
  }, [filters]);

  function fetchMarketingInsights() {
    setLoading(true);
    const params = [];
    Object.entries(filters).forEach(([key, val], index) => {
      if (val && val !== '') {
        params.push(`${key}=${val}`);
      }
    });

    let url = `/api/marketing`;
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    axiosInstance
      .get(url)
      .then((res) => {
        setMarketingData(res['data'].marketingInsights);
        setSummaryData(res.data.summaryStats);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the insights"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getTextFieldFilter(label, type, placeholder) {
    let customProps = {
      value: filters[label],
      label: splitByUnderscoresAndCapitalize(label),
      size: 'small',
      type,
      placeholder,
    };

    if (type === 'date') {
      customProps = {
        ...customProps,
        InputLabelProps: { shrink: true },
      };
    }

    return (
      <TextField
        sx={{ width: '15rem' }}
        onChange={(e) => setFilters({ ...filters, [label]: e.target.value })}
        {...customProps}
      />
    );
  }

  function getStatusDropdown() {
    return (
      <FormControl
        id='status'
        size='small'
        sx={{ width: '15rem' }}
      >
        <InputLabel id='lead_status'>Status</InputLabel>
        <Select
          label='Status'
          labelId='lead_status'
          value={filters?.status}
          onChange={(e) => setFilters({ ...filters, status: e.target.value })}
        >
          <MenuItem value=''>All</MenuItem>
          {statuses.length > 0 &&
            statuses.map((statusObj, index) => {
              return (
                <MenuItem value={statusObj.status}>
                  {splitByUnderscoresAndCapitalize(statusObj.status)}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }

  function getUtmCampaignTypeDropdown() {
    return (
      <FormControl
        id='utm_campaign_type'
        size='small'
        sx={{ width: '14em' }}
      >
        <InputLabel id='utm_campaign_type'>Utm campaign type</InputLabel>
        <Select
          label='utm_campaign_type'
          labelId='utm_campaign_type'
          value={filters?.utm_campaign_type}
          onChange={(e) =>
            setFilters({ ...filters, utm_campaign_type: e.target.value })
          }
        >
          <MenuItem value=''>All</MenuItem>
          <MenuItem value='inbound'>Inbound</MenuItem>
          <MenuItem value='outbound'>Outbound</MenuItem>
        </Select>
      </FormControl>
    );
  }
  return (
    <Stack
      spacing={3}
      sx={{ m: 5 }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Stack
              direction='row'
              useFlexGap
              flexWrap='wrap'
              gap={2}
            >
              {getTextFieldFilter('utm_source', 'text', 'utm_source', {
                width: '30%',
              })}
              {getTextFieldFilter('utm_medium', 'text', 'utm_medium', {
                width: '30%',
              })}
              {getTextFieldFilter(
                'utm_campaign',
                'text',
                'Enter , separated values',
                '100%',
                { width: '40%' }
              )}
            </Stack>

            <Stack
              direction='row'
              useFlexGap
              flexWrap='wrap'
              gap={2}
            >
              {/* Add the remaining filters here */}
              {getStatusDropdown('100%', { width: '10%' })}
              {getTextFieldFilter('from', 'date', '100%', { width: '30%' })}
              {getTextFieldFilter('to', 'date', '100%', { width: '30%' })}
            </Stack>
            <Stack
              direction='row'
              spacing={1}
              justifyContent='flex-start'
              alignItems='center'
              sx={{
                width: '100%',
                flexWrap: 'wrap',
                padding: '5px',

                '@media (max-width: 600px)': {
                  justifyContent: 'flex-start',
                },
              }}
            >
              {shortCutArray.map((shortcutType) => (
                <Chip
                  key={shortcutType}
                  label={splitByUnderscoresAndCapitalize(shortcutType)}
                  onClick={() => {
                    if (shortcutFilter === shortcutType) {
                      //--------- commented the below code so that user can't unselecting the filters -------
                      // setShortcutFilter('');
                      // setFilters({
                      //   ...filters,
                      //   from: '',
                      //   to: '',
                      // });
                    } else {
                      setShortcutFilter(shortcutType);
                      setFilters({
                        ...filters,
                        ...allShortcuts[shortcutType],
                      });
                    }
                  }}
                  sx={{
                    maxWidth: '200px',
                    marginBottom: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    color: shortcutFilter === shortcutType ? 'white' : '',
                    backgroundColor:
                      shortcutFilter === shortcutType ? '#3559E0' : '',
                    '&:hover': {
                      background: '#3559E0',
                      color: 'white',
                    },
                  }}
                />
              ))}
              <Button
                variant='outlined'
                color='primary'
                onClick={handleResetFilters}
                sx={{ maxWidth: '200px', marginTop: '10px' }}
              >
                Reset Filters
              </Button>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mr: 5, marginTop: '-2.5rem' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
          >
            <Tab label='Lead Generation' />
            <Tab label='Lead Funnel' />
          </Tabs>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            calignItems: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          {isloading && <CircularProgress />}
        </Box>
        <TabPanel
          value={currentTab}
          index={0}
        >
          {Object.keys(summaryData).length !== 0 && (
            <Summary
              summaryData={summaryData}
              expandedSummary={expandedSummary}
              setExpandedSummary={setExpandedSummary}
              marketingData={marketingData}
            />
          )}
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={1}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '105%',
            }}
          >
            <LeadFunnel summaryData={summaryData} />
          </Box>
        </TabPanel>
      </Box>
    </Stack>
  );
}
