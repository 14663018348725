export const newLeadsColumnsOrder = [
  'fname',
  'lname',
  'email',
  'mobile',
  'whatsapp',
  'lead_source_type',
  'college',
  'branch',
  'graduation_year',
  'company',
  'linkedin_link',
  'github_link',
  'experience',
  'tech_stack',
  'role',
  'current_salary',
  'desired_salary',
  'current_location',
  'total_experience',
  'portfolio_link',
  'internship_experience',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_region',
  'utm_adset',
  'utm_campaign_type',
  'product',
];

export const metaOrLinkedinFixedColumns = {
  fname: [
    'First name',
    'first name',
    'First_name',
    'first_name',
    'full name',
    'full_name',
    'Full_name',
    'Full name',
    //constants for webinar
    'First Name',
    //constants for hackerEarth
    'Name',
  ],
  lname: ['Last name', 'Last Name'],
  email: ['Email address', 'email', 'Email'],
  mobile: ['Phone number', 'phone_number', 'Phone', 'Contact'],
  role: ['Job title', 'job title', 'job_title', 'Job_title', 'Designation'],
  company: [
    'Company name',
    'company_name',
    'company name',
    'Company_name',
    'Company',
  ],
  experience: [
    'How many Years of work experience?',
    'Work Experience',
    'work experience',
    'work_experience',
    'Work_Experience',
    'how_many_years_of_work_experience_you_have?',
    'Years of work experience ?',
    'Years of Experience',
  ],
  graduation_year: [
    'In which year did you graduated?',
    'in_which_year_did_you_graduate?',
    'What year did you graduate?',
    //constants for hackerEarth
    'Graduation Year',
  ],
  tech_stack: [
    'Your preferred programming language?',
    'preferred_programming_language?',
    'Preferred Programming language?',
  ],
};

export const metaOrLinkedinFixedColumnsObj = {
  // First name variations
  'First name': 'fname',
  'first name': 'fname',
  First_name: 'fname',
  first_name: 'fname',
  'full name': 'fname',
  full_name: 'fname',
  Full_name: 'fname',
  'Full name': 'fname',
  //constants for webinar
  'First Name': 'fname',
  //constants for hackerEarth
  Name: 'fname',

  // Last name variations
  'Last name': 'lname',
  'Last Name': 'lname',

  // Email address variations
  'Email address': 'email',
  email: 'email',
  Email: 'email',

  // Phone number variations
  'Phone number': 'mobile',
  phone_number: 'mobile',
  Phone: 'mobile',
  Contact: 'mobile',

  // Job title variations
  'Job title': 'role',
  'job title': 'role',
  Designation: 'role',

  // Company name variations
  'Company name': 'company',
  company_name: 'company',
  Company: 'company',

  // Work experience variations
  'How many Years of work experience?': 'experience',
  'how_many_years_of_work_experience_you_have?': 'experience',
  'Years of work experience ?': 'experience',
  'Work Experience': 'experience',
  'work experience': 'experience',
  work_experience: 'experience',
  Work_Experience: 'experience',
  'Years of Experience': 'experience',

  // Graduation year variations
  'In which year did you graduated?': 'graduation_year',
  'in_which_year_did_you_graduate?': 'graduation_year',
  'What year did you graduate?': 'graduation_year',
  'Graduation Year': 'graduation_year',

  // Preferred programming language variations
  'Your preferred programming language?': 'tech_stack',
  'preferred_programming_language?': 'tech_stack',
  'Preferred Programming language?': 'tech_stack',
};
