import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import AddIcon from '@mui/icons-material/Add';
import CommonModal from 'components/common/commonModal';
import { TextField } from '@mui/material';
import constants from 'utils/constants';

const FullScreenUserModal = ({
  selectedUser,
  setOpenFullScreenDialog,
  openFullScreenDailog,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
}) => {
  const [userData, setUserData] = React.useState({});
  const [selectedFeaturesForUpdate, setSelectedFeaturesForUpdate] =
    React.useState([]);
  const [selectedGroupsForUpdate, setSelectedGroupsForUpdate] = React.useState(
    []
  );
  const [errorMessage, setErrorMessage] = React.useState([]);
  const [openAddFeatureModal, setOpenAddFeatureModal] = React.useState(false);
  const [openAddGroupModal, setOpenAddGroupModal] = React.useState(false);
  const [inactiveFeatures, setInactiveFeatures] = React.useState([]);
  const [selectedFeatures, setSelectedFeatures] = React.useState([]);
  const [inactiveGroups, setInactiveGroups] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);

  React.useEffect(() => {
    if (selectedUser?.user_id) {
      getUserById(selectedUser?.user_id);
    }
  }, [selectedUser?.user_id]);

  const getUserById = async (id) => {
    const response = await axiosInstance.get(
      `/api/v1/access-table/${id}/users`
    );
    setUserData(response?.data?.data?.data);
    // Set initial features and groups into state
    setSelectedFeaturesForUpdate(
      response?.data?.data?.data?.user_details?.user_features || []
    );
    setSelectedGroupsForUpdate(
      response?.data?.data?.data?.user_details?.user_groups || []
    );
  };

  const handleClose = () => {
    setOpenFullScreenDialog(false);
  };

  const allGroups = userData?.user_details?.user_groups;
  const allFeatures = userData?.user_details?.user_features;
  const handleUpdate = async () => {
    try {
      const removedFeatures = allFeatures
        .filter(
          (feature) =>
            !selectedFeaturesForUpdate.some(
              (selected) => selected.feature_id === feature.feature_id
            )
        )
        .map((feature) => ({
          feature_id: feature.feature_id,
          status: 'inactive',
        }));

      const removedGroups = allGroups
        .filter(
          (group) =>
            !selectedGroupsForUpdate.some(
              (selected) => selected.group_id === group.group_id
            )
        )
        .map((group) => ({
          group_id: group.group_id,
          status: 'inactive',
        }));

      const data = {};
      if (removedFeatures.length > 0) {
        data.features = [...removedFeatures];
      }
      if (removedGroups.length > 0) {
        data.groups = [...removedGroups];
      }

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedUser?.user_id}/users`,
        { data }
      );
      if (response.data.code == 200) {
        setIsSuccess(true);
        setSuccessMessage('Users updated successfully');
      }
      handleClose();
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error while updating access');
      console.error('Error updating user:', error);
    }
  };

  const handleFeatureToggle = (feature) => {
    setSelectedFeaturesForUpdate((prevSelectedFeatures) => {
      const isAlreadyActive = prevSelectedFeatures.some(
        (selected) => selected.feature_id === feature.feature_id
      );
      if (isAlreadyActive) {
        return prevSelectedFeatures.filter(
          (selected) => selected.feature_id !== feature.feature_id
        );
      } else {
        return [...prevSelectedFeatures, feature];
      }
    });
  };

  const handleGroupToggle = (group) => {
    setSelectedGroupsForUpdate((prevSelectedGroups) => {
      const isAlreadyActive = prevSelectedGroups.some(
        (selected) => selected.group_id === group.group_id
      );
      if (isAlreadyActive) {
        return prevSelectedGroups.filter(
          (selected) => selected.group_id !== group.group_id
        );
      } else {
        return [...prevSelectedGroups, group];
      }
    });
  };

  const handleOpenAddFeatureModal = () => {
    setOpenAddFeatureModal(true);
  };

  const handleCloseAddFeatureModal = () => {
    setOpenAddFeatureModal(false);
  };

  const handleOpenAddGroupModal = () => {
    setOpenAddGroupModal(true);
  };

  const handleCloseAddGroupModal = () => {
    setOpenAddGroupModal(false);
  };

  // Inside modal functionality
  React.useEffect(() => {
    if (openAddFeatureModal) {
      fetchActiveFeatures();
    }
    if (openAddGroupModal) {
      fetchActiveGroups();
    }
  }, [openAddFeatureModal, openAddGroupModal]);

  const fetchActiveFeatures = async () => {
    try {
      const allFeatures = userData?.feature_and_groups?.features || [];
      const userFeatures = userData?.user_details?.user_features || [];
      const inactiveFeatures = allFeatures.filter(
        (user) =>
          !userFeatures.some(
            (featureUser) => featureUser.feature_name === user.feature_name
          )
      );
      setInactiveFeatures(inactiveFeatures);
    } catch (error) {
      console.error('Error fetching inactive users:', error);
    }
  };

  const fetchActiveGroups = async () => {
    try {
      const allGroups = userData?.feature_and_groups?.groups || [];
      const userGroups = userData?.user_details?.user_groups || [];
      console.log('allGroups,userGroups', allGroups, userGroups);

      const inactiveGroups = allGroups.filter(
        (group) =>
          !userGroups.some(
            (userGroup) => userGroup.group_name === group.group_name
          )
      );
      setInactiveGroups(inactiveGroups);
    } catch (error) {
      console.error('Error fetching inactive Groups:', error);
    }
  };

  const handleFeatureUserToggle = (userId) => {
    setSelectedFeatures((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleGroupFeatureToggle = (userId) => {
    setSelectedGroups((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleAddFeature = async () => {
    try {
      const featuresPayload = selectedFeatures.map((feature_id) => ({
        feature_id,
      }));

      const payload = {
        data: {
          features: featuresPayload,
        },
      };

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedUser?.user_id}/users`,
        payload
      );

      if (response.data.code === 200) {
        if (featuresPayload.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('User Features updated successfully');
        }
        fetchActiveFeatures();
        await getUserById(selectedUser?.user_id);
        handleCloseAddFeatureModal();
      } else {
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      console.error('Error updating users:', error);
    }
  };

  const handleAddGroup = async () => {
    try {
      const groupsPayload = selectedGroups.map((group_id) => ({
        group_id,
        status: 'active',
      }));

      const payload = {
        data: {
          groups: groupsPayload,
        },
      };

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedUser?.user_id}/users`,
        payload
      );

      if (response.data.code === 200) {
        if (groupsPayload.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('User Groups updated successfully');
        }
        setSelectedGroupsForUpdate((prevGroups) => [
          ...prevGroups,
          ...inactiveGroups.filter((group) =>
            selectedGroups.includes(group.group_id)
          ),
        ]);

        // Optionally, update userData for consistency
        setUserData((prevUserData) => ({
          ...prevUserData,
          user_details: {
            ...prevUserData.user_details,
            user_groups: [
              ...prevUserData.user_details.user_groups,
              ...inactiveGroups.filter((group) =>
                selectedGroups.includes(group.group_id)
              ),
            ],
          },
        }));

        fetchActiveGroups();
        handleCloseAddGroupModal();
      } else {
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      console.error('Error updating users:', error);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '900px', // Medium size
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <React.Fragment>
      <Modal
        open={openFullScreenDailog}
        onClose={handleClose}
        aria-labelledby='user-modal-title'
        aria-describedby='user-modal-description'
      >
        <Box sx={modalStyle}>
          <Box align='right'>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              mx: 3,
            }}
          >
            <TextField
              id='editable-group-name'
              variant='standard'
              value={selectedUser?.name}
              size='small'
              fullWidth
            />
          </Box>

          <Container sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
              <Typography>Email: {selectedUser?.email}</Typography>
              <Typography>Status: {selectedUser?.status}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              {/* Users Section */}

              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 2,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    component='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Features
                  </Typography>
                  <IconButton
                    onClick={handleOpenAddFeatureModal}
                    color='primary'
                  >
                    <AddIcon />
                  </IconButton>
                </Box>

                {allFeatures?.map((feature) => (
                  <Box
                    key={feature.feature_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.9rem',
                        wordBreak: 'break-word',
                      }}
                    >
                      {feature.feature_name}
                    </Typography>
                    {feature.status === constants.ACTIVE && (
                      <Switch
                        checked={selectedFeaturesForUpdate.some(
                          (featureUser) =>
                            featureUser.feature_id === feature.feature_id
                        )}
                        onChange={() => handleFeatureToggle(feature)}
                        color='primary'
                      />
                    )}
                  </Box>
                ))}
              </Box>

              <CommonModal
                open={openAddFeatureModal}
                onClose={handleCloseAddFeatureModal}
                title='Add Features'
                onAction={handleAddFeature}
                actionText='Add Features'
              >
                <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {inactiveFeatures.length === 0 ? (
                    <Typography>No Active Features Found</Typography>
                  ) : (
                    inactiveFeatures.map((user) => (
                      <Box
                        key={user.feature_id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: 1,
                          p: 1,
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        <Typography sx={{ flex: 1 }}>
                          {user.feature_name}
                        </Typography>
                        <Switch
                          checked={selectedFeatures.includes(user.feature_id)}
                          onChange={() =>
                            handleFeatureUserToggle(user.feature_id)
                          }
                          color='primary'
                        />
                      </Box>
                    ))
                  )}
                </Box>
              </CommonModal>
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 2,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    component='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Groups
                  </Typography>
                  <IconButton
                    onClick={handleOpenAddGroupModal}
                    color='primary'
                  >
                    <AddIcon />
                  </IconButton>
                </Box>

                {allGroups?.map((group) => (
                  <Box
                    key={group.group_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.9rem',
                        wordBreak: 'break-word',
                      }}
                    >
                      {group.group_name}
                    </Typography>
                    <Switch
                      checked={selectedGroupsForUpdate.some(
                        (userGroup) => userGroup.group_id === group.group_id
                      )}
                      onChange={() => handleGroupToggle(group)}
                      color='primary'
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <CommonModal
              open={openAddGroupModal}
              onClose={handleCloseAddGroupModal}
              title='Add Groups'
              onAction={handleAddGroup}
              actionText='Add Groups'
            >
              <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                {inactiveGroups.length === 0 ? (
                  <Typography>No Inactive Groups Found</Typography>
                ) : (
                  inactiveGroups.map((group) => (
                    <Box
                      key={group.user_id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1,
                        p: 1,
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <Typography sx={{ flex: 1 }}>
                        {group.group_name}
                      </Typography>
                      <Switch
                        checked={selectedGroups.includes(group.group_id)}
                        onChange={() =>
                          handleGroupFeatureToggle(group.group_id)
                        }
                        color='primary'
                      />
                    </Box>
                  ))
                )}
              </Box>
            </CommonModal>
            <Box sx={{ mt: 3 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdate}
                sx={{ width: '100%' }}
              >
                Update
              </Button>
            </Box>
          </Container>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default FullScreenUserModal;
