import { createSlice } from '@reduxjs/toolkit';
import { fetchCampaigns } from './campaignsThunk';

const campaignSlice = createSlice({
  name: 'campaigns',
  initialState: {
    campaigns: [],
    utmUniqueSource: [],
    utmUniqueMedium: [],
    statusUnique: [],
    campaignName: [],
    totalCampaigns: 0,
    page: 0,
    loading: false,
    successMessage: null,
    errorMessage: null,
  },
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        const {
          campaigns,
          totalCampaigns,
          utmUniqueSource,
          utmUniqueMedium,
          statusUnique,
          campaignName,
        } = action.payload;
        state.campaigns = campaigns;
        state.totalCampaigns = totalCampaigns;
        state.utmUniqueSource = utmUniqueSource;
        state.utmUniqueMedium = utmUniqueMedium;
        state.statusUnique = statusUnique;
        state.campaignName = campaignName;
        state.loading = false;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      });
  },
});

export const { setPage } = campaignSlice.actions;
export default campaignSlice.reducer;
