import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from 'apis/axiosInstance';

export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async (
    { page, page_limit, filters, numericFilterCriteria },
    { rejectWithValue }
  ) => {
    try {
      const params = [];

      Object.entries(filters).forEach(([key, val]) => {
        if (val !== '') {
          if (key === 'start_date' || key === 'end_date') {
            if (!isNaN(Date.parse(val))) {
              params.push(`${key}=${encodeURIComponent(val)}`);
            }
          } else {
            params.push(`${key}=${encodeURIComponent(val)}`);
          }
        }
      });

      if (numericFilterCriteria) {
        const { operator, value } = numericFilterCriteria;
        if (operator && value) {
          const queryString = `value=${value}&operator=${operator}`;
          params.push(queryString);
        }
      }
      // Add pagination parameters
      params.push(`page=${page}`);
      params.push(`per_page=${page_limit}`);

      const url = `/api/campaigns?${params.join('&')}`;

      const response = await axiosInstance.get(url);
      const { responseData, totalCampaigns } = response.data.data;

      // Map and process data
      const originalData = responseData.campaigns;
      const modifiedData = originalData.map((item, index) => ({
        ...item,
        index: index + 1 + page * page_limit, // Adjust index based on current page
      }));

      const uniqueSource = Array.from(
        new Set(originalData.map((item) => item.utm_source))
      );
      const uniqueMedium = Array.from(
        new Set(originalData.map((item) => item.utm_medium))
      );
      const uniqueStatus = Array.from(
        new Set(originalData.map((item) => item.status))
      );
      const campaignName = Array.from(
        new Set(originalData.map((item) => item.utm_campaign))
      );

      return {
        campaigns: modifiedData,
        totalCampaigns,
        uniqueSource,
        uniqueMedium,
        uniqueStatus,
        campaignName,
      };
    } catch (err) {
      toast.error(err.response?.data?.message || 'Could not fetch campaigns.');
      return rejectWithValue(
        err.response?.data?.message || 'Could not fetch campaigns.'
      );
    }
  }
);
