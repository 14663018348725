import axiosInstance from 'apis/axiosInstance';
import constants from '../../utils/constants';
import { toast } from 'react-toastify';
const {
  NOT_CALLED,
  INITITATED,
  STARTED,
  ANSWERED,
  MISSED,
  HANGUP,
  HANGUPWITHANSWERED,
} = constants;

// Initiate a call
export const handleMakeCallLogicUsingTelecmi = async (currentLead) => {
  // Proceed with initiating the call
  localStorage.setItem('callHappening', JSON.stringify(true));
  const leadDetail = {
    leadId: currentLead?.id,
    mode: 'telecmi',
    DNPObject: {},
  };

  try {
    const response = await axiosInstance.post(
      '/api/telecmi_initiate_call',
      leadDetail
    );

    if (response?.data?.success) {
      return {
        action: 'call_initiated',
        successMessage: 'Call is initiated.',
      };
    } else {
      return {
        action: 'error',
        errorMessage: 'Something went wrong, call cannot be initiated.',
      };
    }
  } catch (error) {
    console.error('Error initiating call:', error);
    localStorage.removeItem('callHappening');
    return {
      action: 'error',
      errorMessage: error.response?.data?.message || 'Call initiation failed.',
    };
  }
};

// Fetch current incoming call status
export const getCurrentIncomingCallStatus = async (
  leadId,
  setInterectionBoxes
) => {
  try {
    const res = await axiosInstance.get('/api/call-incoming-status');
    const currentStatus = res.data;

    if (currentStatus === ANSWERED) {
      setInterectionBoxes(true);
    } else if (currentStatus === HANGUPWITHANSWERED) {
      await axiosInstance.post('/api/interaction-box-visibility', {
        leadId,
        status: ANSWERED,
      });
    }
  } catch (error) {
    console.error('Error fetching incoming call status:', error);
  }
};

// Fetch current call status and handle state updates
export const getCurrentCallStatus = async (
  leadId,
  handleError,
  setInterectionBoxes,
  clearCallData,
  handleHangupFunction,
  setCallHappening,
  setIsSuccess,
  setSuccessText,
  updateCurrentLeadContexts,
  pollingRef,
  isPooling
) => {
  try {
    const storedValue = JSON.parse(localStorage.getItem('callHappening'));
    if (!storedValue) {
      pollingRef.current = null;
      isPooling = false;
      return;
    }
    const res = await axiosInstance.get(`/api/call-status/${leadId}`);
    const { hangup_lead, status_lead, status_admin } = res?.data?.data;

    handleStatusUpdate(
      status_admin,
      status_lead,
      hangup_lead,
      setInterectionBoxes,
      setCallHappening,
      setIsSuccess,
      setSuccessText,
      handleHangupFunction,
      updateCurrentLeadContexts,
      clearCallData
    );

    if (isPooling && shouldContinuePolling(status_admin, status_lead)) {
      pollingRef.current = setTimeout(() => {
        getCurrentCallStatus(
          leadId,
          handleError,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          updateCurrentLeadContexts,
          pollingRef,
          isPooling
        );
      }, 15000);
    }
  } catch (error) {
    handleError(error);
    console.error('Error fetching call status:', error);
  }
};

// Handle call status updates
const handleStatusUpdate = (
  status_admin,
  status_lead,
  hangup_lead,
  setInterectionBoxes,
  setCallHappening,
  setIsSuccess,
  setSuccessText,
  handleHangupFunction,
  updateCurrentLeadContexts,
  clearCallData
) => {
  switch (true) {
    case status_admin === INITITATED:
      updateCallState(
        true,
        false,
        'Call initiated.',
        setCallHappening,
        setIsSuccess,
        setSuccessText
      );
      break;

    case status_lead === STARTED:
      updateCallState(
        true,
        false,
        'Call is ringing.',
        setCallHappening,
        setIsSuccess,
        setSuccessText
      );
      break;

    case status_lead === ANSWERED:
      localStorage.setItem('callStatus', status_lead);
      updateCallState(
        true,
        false,
        'Call is answered.',
        setCallHappening,
        setIsSuccess,
        setSuccessText
      );
      setInterectionBoxes(true);
      break;

    case status_lead === HANGUP || status_admin === HANGUP:
      updateCallState(
        false,
        true,
        'Call has ended.',
        setCallHappening,
        setIsSuccess,
        setSuccessText
      );
      if (status_lead === HANGUP) {
        handleHangupFunction(hangup_lead);
      }
      clearCallData();
      localStorage.removeItem('callHappening');
      localStorage.removeItem('callStatus');
      updateCurrentLeadContexts();
      break;

    case status_lead === MISSED || status_admin === MISSED:
      updateCallState(
        false,
        false,
        `${
          status_admin === MISSED
            ? 'You missed a call.'
            : 'Call missed by lead.'
        }`,
        setCallHappening,
        setIsSuccess,
        setSuccessText
      );
      clearCallData();
      localStorage.removeItem('callHappening');
      localStorage.removeItem('callStatus');
      updateCurrentLeadContexts();
      break;

    default:
      break;
  }
};

// Determine if polling should continue
const shouldContinuePolling = (status_admin, status_lead) => {
  const isAdminHangupOrMissed = [HANGUP, MISSED].includes(status_admin);
  const isLeadHangupOrMissed = [HANGUP, MISSED].includes(status_lead);
  if (isAdminHangupOrMissed || isLeadHangupOrMissed) {
    return false;
  }

  return true;
};

// Update call state helper
const updateCallState = (
  isHappening,
  isHangup,
  successText,
  setCallHappening,
  setIsSuccess,
  setSuccessText
) => {
  setCallHappening(isHappening);
  setIsSuccess(true);
  setSuccessText(successText);
};

// Fetch Telecmi-enabled users
export const getTelecmiEnabledUsers = async (
  setTelecmiEnabledUsers,
  setHasError,
  setErrorText
) => {
  try {
    const response = await axiosInstance.get('/api/telecmi_users');
    setTelecmiEnabledUsers(response?.data || false);
  } catch (error) {
    setHasError(true);
    setErrorText(
      error.response?.data?.message || 'Failed to fetch Telecmi users.'
    );
    console.error('Error fetching Telecmi users:', error);
  }
};

export const fetchTelecmiUserData = async (adminId) => {
  try {
    const res = await axiosInstance.get(`api/fetch-telecmi-details/${adminId}`);
    return res?.data?.data;
  } catch (error) {
    toast.error('Error occured while fetching the telecmi user details ');
  }
};
