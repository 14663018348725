import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import constants from 'utils/constants';

const daysOfWeek = [
  constants.SUNDAY,
  constants.MONDAY,
  constants.TUESDAY,
  constants.WEDNESDAY,
  constants.THURSDAY,
  constants.FRIDAY,
  constants.SATURDAY,
];

const HolidaySummary = ({ title, type, onSave, selectedDays }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDay, setEditedDay] = useState(selectedDays);

  useEffect(() => {}, [selectedDays]);

  const handleSave = () => {
    setIsEditing(false);

    if (selectedDays === editedDay) {
      toast.error(`No change in ${title}`);
      return;
    }
    onSave({ title, type, day: editedDay });
  };

  const handleCancel = () => {
    setEditedDay(selectedDays);
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '50%',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <strong>{title}:</strong>{' '}
        {!isEditing ? (
          <span style={{ marginLeft: '8px' }}>
            {editedDay ? editedDay : 'None'}
          </span>
        ) : (
          <FormControl
            fullWidth
            sx={{ margin: '10px' }}
          >
            <InputLabel>Select Day</InputLabel>
            <Select
              value={editedDay || ''}
              onChange={(e) => setEditedDay(e.target.value)}
              label='Select Day'
              sx={{ width: '100%', margin: '5px' }}
            >
              {daysOfWeek.map((day) => (
                <MenuItem
                  key={day}
                  value={day}
                >
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {!isEditing ? (
        <Button
          variant='outlined'
          size='small'
          onClick={() => setIsEditing(true)}
          sx={{ width: '20%', textAlign: 'center' }}
        >
          Edit
        </Button>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
            flex: 1,
          }}
        >
          <Button
            variant='outlined'
            size='small'
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HolidaySummary;
