import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import { useEffect } from 'react';
import DrivesTable from './DrivesTable';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import { TabPanel } from '../AdminDashboard';
import IncentiveTab from './IncentiveTab';

export default function Drives() {
  const [openNewDriveModal, setOpenNewDriveModal] = useState(false);
  const [allDrives, setAllDrives] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchAllDrives();
    return () => {};
  }, []);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  function fetchAllDrives() {
    axiosInstance
      .get(`/api/drives`, { withCredentials: true })
      .then((res) => {
        if (res.data) {
          setAllDrives(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch the drives.'
        );
      });
  }

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: '10px',
          marginBottom: '20px',
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant='scrollable'
        >
          <Tab label='Drive Lists' />
          <Tab label='Incentive Structure' />
        </Tabs>
      </Box>

      <TabPanel
        value={currentTab}
        index={0}
      >
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          <Button
            variant='contained'
            onClick={(e) => setOpenNewDriveModal(true)}
          >
            Add new drive
          </Button>
        </Box>

        {openNewDriveModal && (
          <NewDriveModal
            {...{
              openNewDriveModal,
              setOpenNewDriveModal,
              allDrives,
              setAllDrives,
              setSuccessMessage,
              setErrorMessage,
              setIsSuccess,
              setHasError,
            }}
          />
        )}
        {allDrives.length > 0 && (
          <DrivesTable
            {...{
              allDrives,
              setAllDrives,
              setSuccessMessage,
              setErrorMessage,
              setIsSuccess,
              setHasError,
            }}
          />
        )}
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={1}
      >
        <IncentiveTab />
      </TabPanel>
    </Box>
  );
}

function NewDriveModal({
  openNewDriveModal,
  setOpenNewDriveModal,
  setAllDrives,
  allDrives,
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) {
  const [modalData, setModalData] = useState({
    name: '',
    starts_at: getDefaultStartDate(),
    ends_at: getDefaultEndDate(),
    incentive_structure_id: '',
  });
  const [incentiveStructures, setIncentiveStructures] = useState([]);
  useEffect(() => {
    fetchIncentiveStructures();
  }, []);

  function getDefaultStartDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const day = String(tomorrow.getDate()).padStart(2, '0');

    return `${year}-${month}-${day} 00:00`; // Format: "YYYY-MM-DD 00:00"
  }

  // Function to get default end date (11:59 PM)
  function getDefaultEndDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2);
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const day = String(tomorrow.getDate()).padStart(2, '0');

    return `${year}-${month}-${day} 23:59`; // Format: "YYYY-MM-DD 00:00"
  }

  function fetchIncentiveStructures() {
    axiosInstance
      .get(`/api/drives/incentive/structure`)
      .then((res) => {
        setIncentiveStructures(res.data); // Store the fetched incentive structures in state
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch incentive structures.'
        );
      });
  }
  const handleIncentiveStructureChange = (event) => {
    setModalData({ ...modalData, incentive_structure_id: event.target.value });
  };
  function renderIncentiveStructureDropdown() {
    return (
      <>
        <p style={{ mt: 1 }}>Incentive Structure</p>
        <Select
          value={modalData.incentive_structure_id}
          onChange={handleIncentiveStructureChange}
          placeholder='select the incentive structure'
          required
          fullWidth
          sx={{ mt: 1 }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300, // Max height of the dropdown menu
                width: 'auto', // Width of the dropdown menu
              },
            },
          }}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {incentiveStructures &&
            incentiveStructures.length > 0 &&
            incentiveStructures.map((structure) => (
              <MenuItem
                key={structure.id}
                value={structure.id}
              >
                {structure.name}
              </MenuItem>
            ))}
        </Select>
      </>
    );
  }

  function getDriveNameTextField() {
    return (
      <>
        <p>Name</p>
        <TextField
          required
          fullWidth
          sx={{ mt: 1 }}
          label='Name'
          placeholder='Add drive Name here'
          value={modalData.name}
          onChange={(e) => setModalData({ ...modalData, name: e.target.value })}
        />
      </>
    );
  }

  function getDriveDatesField(fieldType) {
    return (
      <TextField
        required
        type='datetime-local'
        name='drive_date'
        value={fieldType === 'start' ? modalData.starts_at : modalData.ends_at}
        label={
          fieldType === 'start'
            ? 'Drive start date in IST'
            : 'Drive end date in IST'
        }
        InputLabelProps={{ shrink: true }}
        sx={{ mt: 2 }}
        onChange={(e) => {
          if (fieldType === 'start') {
            setModalData({ ...modalData, starts_at: e.target.value });
          } else {
            setModalData({ ...modalData, ends_at: e.target.value });
          }
        }}
      />
    );
  }

  const handleAddClick = (e) => {
    if (
      modalData.starts_at === '' ||
      modalData.ends_at === '' ||
      modalData.name === ''
      // modalData.incentive_structure_id === ''
    ) {
      setHasError(true);
      setErrorMessage('All field are required');
      return;
    }
    const body = { ...modalData };
    const starts_at = new Date(modalData.starts_at);
    const ends_at = new Date(modalData.ends_at);
    body['starts_at'] = starts_at.toUTCString();
    body['ends_at'] = ends_at.toUTCString();
    axiosInstance
      .post(`/api/drives`, body, { withCredentials: true })
      .then((res) => {
        setAllDrives([res.data, ...allDrives]);
        setIsSuccess(true);
        setSuccessMessage('New drive successfully added.');
        setOpenNewDriveModal(false);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add the drive.'
        );
      });
  };

  return (
    <Dialog
      open={openNewDriveModal}
      onClose={(e) => setOpenNewDriveModal(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          onClick={(e) => setOpenNewDriveModal(false)}
          style={{ fontSize: '30px' }}
        >
          &times;
        </Button>
      </Box>

      <DialogContent>
        <DialogContentText>
          <Box>
            <Typography
              variant='h6'
              sx={{ mb: 2 }}
            >
              Add New Drive
            </Typography>
          </Box>
          {getDriveNameTextField()}
          {renderIncentiveStructureDropdown()}
          <Box
            display='flex'
            justifyContent='space-between'
          >
            {getDriveDatesField('start')}
            {getDriveDatesField('end')}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ textAlign: 'center', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={handleAddClick}
        >
          Add drive
        </Button>
      </DialogActions>
    </Dialog>
  );
}
