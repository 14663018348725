import axios from 'axios';
import { base_url } from '../components/Mode'; // Ensure this exports a function correctly
import store, { persistor } from '../redux/store';
import { logout } from 'features/auth/authSlice';
import { toast } from 'react-toastify';
import constants from 'utils/constants';

let isSessionExpiredToastShown = false; // Flag to prevent duplicate toasts

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: base_url(),
  timeout: 60000,
  withCredentials: true, // Enable sending cookies with requests
});

// Function to inject interceptors with user state and navigation hooks
export const setupAxiosInterceptors = (setUser, history) => {
  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.error('Request Error:', error);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error?.response?.status === 401 ||
        (error?.response?.status === 403 &&
          error?.response?.data ===
            'Access denied. The required cookies are missing in the request.')
      ) {
        if (!isSessionExpiredToastShown) {
          try {
            isSessionExpiredToastShown = true; // Prevent duplicate toasts
            toast.error('Session expired, please login again');

            setTimeout(() => {
              isSessionExpiredToastShown = false; // Reset flag after a timeout
            }, constants.TOAST_TIMEOUT); // Adjust timeout duration as needed
            localStorage.clear(); // Clear all local storage
            persistor.purge();
            store.dispatch(logout());
            history('/');
          } catch (err) {
            // silent fail
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
