import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  capitalize,
  currentAdminIsAdmin,
  currentAdminIsHr,
} from '../../utils/common';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Snackbar } from '@mui/material';
import columnSets from './columnSets';

const CopyIcon = ({ onClick }) => (
  <ContentCopyIcon
    style={{ cursor: 'pointer', fontSize: 'small', margin: '10px' }}
    onClick={onClick}
  />
);

const CampaignsTable = ({
  updateCampaignApiCall,
  tableData,
  loading,
  selectedButton,
  setTableData,
  page,
  onPageChange,
  totalRows,
  perPage,
}) => {
  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
  };

  const handleRowDoubleClick = (params) => {
    updateCampaignApiCall(params.row);
  };

  const renderStatusCell = (params) => {
    const status = params.row.status;
    let backgroundColor = '';
    if (status === 'draft') {
      backgroundColor = 'warning';
    } else if (status === 'live') {
      backgroundColor = 'success';
    } else if (status === 'locked') {
      backgroundColor = 'error';
    } else if (status === 'approved') {
      backgroundColor = 'success';
    } else if (status === 'paid') {
      backgroundColor = 'success';
    } else if (status === 'rejected') {
      backgroundColor = 'error';
    }

    return (
      <Chip
        label={capitalize(status)}
        variant='filled'
        size='medium'
        color={backgroundColor}
      />
    );
  };
  const renderUTRCell = (params) => {
    return <>{params.row.utr}</>;
  };

  const renderProcessedAmountCell = (params) => {
    return <>{params.row.processed_amount}</>;
  };

  // Filter the data based on the selected status
  const filteredTableData = currentAdminIsHr()
    ? tableData.filter(
        (row) => row.status === 'approved' || row.status === 'paid'
      )
    : tableData;

  const allcolumns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 50,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params?.row?.index,
    },
    {
      field: 'utm_source',
      headerName: 'Source',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params?.row?.utm_source,
    },
    {
      field: 'utm_medium',
      headerName: 'Medium',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params?.row?.utm_medium,
    },
    {
      field: 'utm_campaign',
      headerName: 'Campaign',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params?.row?.utm_campaign,
    },
    {
      field: 'live_on',
      headerName: 'Live On',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        moment(params?.row?.live_on).format('YYYY-MM-DD '),
    },
    {
      field: 'createdby_admin',
      headerName: 'Created By',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params?.row && params?.row?.admin) {
          const admin = params?.row?.admin;
          return `${admin?.fname} ${admin?.lname}`;
        } else {
          return '';
        }
      },
    },
    {
      field: 'spent_type',
      headerName: 'Cost Type',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => `${capitalize(params.row?.spent_type ?? 0)}`,
    },
    {
      field: 'cost',
      headerName: 'Cost (Rs)',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => `${params.row?.cost ?? 0}`,
    },
    {
      field: 'leads',
      headerName: 'Leads',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {params?.row?.leadData ? (
            <>
              <span style={{ marginBottom: '3px' }}>
                {params.row?.leadData?.leadsCount ?? 0}
              </span>
              <CopyIcon
                onClick={() =>
                  handleCopyClick(params.row?.leadData?.leadsId.join(', '))
                }
              />
            </>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      field: 'app',
      headerName: 'APP',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.leadData?.LeadsCountofeligibilityFormFilled ?? 0,
    },
    {
      field: 'tap',
      headerName: 'TAP',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.leadData?.tokenAmountPaidLeadsCount ?? 0,
    },
    {
      field: 'cap',
      headerName: 'CAP',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.leadData?.completeAmountPaidLeadsCount ?? 0,
    },
    {
      field: 'cac',
      headerName: 'CAC',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params?.row?.leadData?.Customer_acquisition_cost ?? 0,
    },
    {
      field: 'utr',
      headerName: 'UTR',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: renderUTRCell,
    },
    {
      field: 'processed_amount',
      headerName: 'Processed Amount',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: renderProcessedAmountCell,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      align: 'center',
      headerAlign: 'center',
      renderCell: renderStatusCell,
    },
    {
      field: 'cpl',
      headerName: 'CPL',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.leadData?.CostPerLead ?? 0,
    },
    {
      field: 'wp',
      headerName: 'WP',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        params.row?.leadData?.LeadsCountofworkingProfessional ?? 0,
      renderCell: (params) => (
        <>
          {params.row?.leadData?.LeadsCountofworkingProfessional}
          <CopyIcon
            onClick={() =>
              handleCopyClick(
                params.row?.leadData?.workingProfessionalLeadIds.join(', ')
              )
            }
          />
        </>
      ),
    },
    {
      field: 'pql',
      headerName: 'PQL',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params?.row?.leadData?.productQualifiedLeads,
    },

    {
      field: 'view',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const isPaid = params?.row?.status === 'paid';
        return isPaid ? (
          <IconButton
            onClick={(e) => updateCampaignApiCall(params?.row)}
            aria-label='view'
          >
            <VisibilityIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={(e) => updateCampaignApiCall(params?.row)}
            aria-label='edit'
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];
  const generateColumns = (selectedButton) => {
    const selectedColumnNames = new Set(columnSets[selectedButton] || []);
    return allcolumns.filter((col) => selectedColumnNames.has(col.field));
  };

  const columns = generateColumns(selectedButton);
  return (
    <>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={perPage}
        rowCount={totalRows}
        page={page}
        onPageChange={(newPage) => onPageChange(newPage)}
        paginationMode='server'
        rowsPerPageOptions={[perPage]}
        pagination
        autoHeight
        loading={loading}
        disableSelectionOnClick
        onCellDoubleClick={handleRowDoubleClick}
      />
    </>
  );
};

export default CampaignsTable;
