import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from 'apis/axiosInstance';
import {
  currentAdminIsAdmin,
  currentAdminIsMarketing,
  currentAdminIsHr,
  currentAdminIsManagerOrAdmin,
  currentAdminIsTa,
  currentAdminIsManager,
} from '../../utils/common';

export const fetchAdminsData = createAsyncThunk(
  'admins/fetchAdminsData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const assigneesRes = await axiosInstance.get(
        `/api/admins/lead-access-policies`
      );
      const assignees = assigneesRes.data.data || [];

      const state = getState();
      const currAdmin = state.auth.currAdmin || {};

      let subordinateAdminIds = new Set(
        assignees.concat(currAdmin.subordinate_admin_ids ?? [currAdmin.id])
      );

      if (currentAdminIsManager()) {
        subordinateAdminIds = new Set(
          [...subordinateAdminIds].filter((id) => assignees.includes(id))
        );
      }

      subordinateAdminIds.add(currAdmin.id);

      const adminsRes = await axiosInstance.get(`/api/admins`);
      const admins = adminsRes.data;
      if (!adminsRes.data) {
        throw new Error('Invalid response from admins API.');
      }

      const enabledAccessAdmins = [];
      const disabledAccessAdmins = [];
      const subordinateAdmins = [];

      admins.forEach((admin) => {
        if (admin.access_type === 'allowed') {
          enabledAccessAdmins.push(admin);
        } else {
          disabledAccessAdmins.push(admin);
        }

        if (
          currentAdminIsAdmin() ||
          currentAdminIsMarketing() ||
          currentAdminIsHr() ||
          currentAdminIsTa() ||
          subordinateAdminIds.has(admin.id)
        ) {
          subordinateAdmins.push(admin);

          if (
            currentAdminIsAdmin() ||
            currentAdminIsHr() ||
            currentAdminIsTa()
          ) {
            subordinateAdminIds.add(admin.id);
          }
        }
      });

      const updatedCurrAdmin = {
        ...currAdmin,
        subordinate_admin_ids: Array.from(subordinateAdminIds),
      };
      return {
        allAdmins: [...enabledAccessAdmins, ...disabledAccessAdmins],
        subordinateAdmins,
        currAdmin: updatedCurrAdmin,
        showRecordingColumn: currentAdminIsManagerOrAdmin(),
        showImportBtn: currentAdminIsManagerOrAdmin(),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addAdmin = createAsyncThunk(
  'admins/addAdmin',
  async (adminData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/admins`, adminData);
      if (!response.data) {
        throw new Error('Invalid response from admins API.');
      }
      toast.success('Admin added successfully.');

      return response.data; // Contains `admin` and `roles`
    } catch (err) {
      toast.error(err.response?.data?.message || 'Could not add the admin.');
      return rejectWithValue(
        err.response?.data?.message || 'Could not add the admin.'
      );
    }
  }
);

export const updateAutoDialerAccess = createAsyncThunk(
  'admins/updateAutoDialerAccess',
  async ({ adminId, updates }, { getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/api/admins/${adminId}/auto_dialer_user`,
        updates
      );
      const updatedAdminData = response?.data.data;

      toast.success(
        `Auto Dialer access ${
          updatedAdminData?.autodialer_access ? 'enabled' : 'disabled'
        } successfully.`
      );

      return {
        adminId,
        autodialer_access: updatedAdminData?.autodialer_access,
      };
    } catch (error) {
      toast.error(error.response?.data || 'Error updating Auto Dialer access.');
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateTelecmiAccess = createAsyncThunk(
  'admins/updateTelecmiAccess',
  async ({ adminId, updates }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/api/admins/${adminId}/telecmi_user`,
        updates
      );

      toast.success(
        `TeleCMI access ${
          updates.telecmi_access ? 'enabled' : 'disabled'
        } successfully.`
      );

      return { adminId, data: response.data.data };
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Error updating TeleCMI access.'
      );
      return rejectWithValue(
        err.response?.data?.message || 'Error updating TeleCMI access.'
      );
    }
  }
);

export const revokeTelecmiAccess = createAsyncThunk(
  'admins/revokeTelecmiAccess',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/revoke-telecmi-access`, {
        email,
      });
      toast.success(`TeleCMI access removed successfully.`);

      return {
        adminId: response?.data?.data?.admin_id,
        data: response?.data?.data,
      };
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Error revoking TeleCMI access.'
      );
      return rejectWithValue(
        err.response?.data?.message || 'Error revoking TeleCMI access.'
      );
    }
  }
);

export const creatingTelecmiUser = createAsyncThunk(
  'admins/creatingTelecmiUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/create-telecmi-user`, {
        data,
      });
      if (response?.data?.code === 200) {
        toast.success(`TeleCMI user created successfully.`);
      } else {
        toast.error('Cannot created user.');
      }

      return {
        adminId: response?.data?.data?.admin_id,
        data: response?.data?.data,
      };
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Error creating TeleCMI access.'
      );
      return rejectWithValue(
        err.response?.data?.message || 'Error creating TeleCMI access.'
      );
    }
  }
);

export const updatingTelecmiUser = createAsyncThunk(
  'admins/updatingTelecmiUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/api/update-telecmi-user-details`,
        {
          data,
        }
      );
      if (response?.data?.code === 200) {
        toast.success(`TeleCMI user updated successfully.`);
      } else {
        toast.error('Cannot update user.');
      }

      return {
        adminId: response?.data?.data?.admin_id,
        data: response?.data?.data,
      };
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Error updating TeleCMI access.'
      );
      return rejectWithValue(
        err.response?.data?.message || 'Error updating TeleCMI access.'
      );
    }
  }
);

export const updateAdmin = createAsyncThunk(
  'admins/updateAdmin',
  async ({ adminId, body }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/api/admins/${adminId}`,
        body
      );

      toast.success('Admin updated successfully.');

      return { adminId, data: response.data };
    } catch (err) {
      toast.error(err.response?.data?.message || 'Could not update the admin.');
      return rejectWithValue(
        err.response?.data?.message || 'Could not update the admin.'
      );
    }
  }
);
