import React, { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import {
  newLeadsColumnsOrder,
  metaOrLinkedinFixedColumns,
} from '../../../utils/importLeadsColumnFormat';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import { base_url } from '../../../components/Mode';
import CustomBackdrop from '../../CustomBackdrop';
import DisplayImportLeads from './DisplayImportLeads';
import DisplayLeadsModal from './DisplayLeadsModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import constants from '../../../utils/constants';
const { FORMATED_CSV, META_OR_LINKEDIN } = constants;

export default function ImportLeads() {
  const { CSVReader } = useCSVReader();
  const [hasImported, setHasImported] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [columns, setColumns] = useState([]);
  const [unRecognizedColumns, setUnRecognizedColumns] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [serverResponse, setServerResponse] = useState({});
  const [isAddingLeads, setIsAddingLeads] = useState(false);
  const [utmCampaignType, setUtmCampaignType] = useState('');
  const [importedFileType, setImportedFileType] = useState('');
  const [leadsData, setLeadsData] = useState([]);

  useEffect(() => {
    if (hasImported) {
      setServerResponse({});
      checkFilesType();
    }
    return () => {};
  }, [hasImported]);

  const checkFilesType = () => {
    const knownColumns = new Set(newLeadsColumnsOrder);
    const unknownColumns = [];

    columns.forEach((col) => {
      if (!knownColumns.has(col)) {
        unknownColumns.push(col);
      }
    });

    if (unknownColumns.length > 0) {
      verifyImportedColumnsForMetaOrLinkedin();
    } else {
      setImportedFileType(FORMATED_CSV);
    }
  };

  const verifyImportedColumnsForMetaOrLinkedin = () => {
    const importedColumns = new Set(columns);
    const missingColumns = [];
    Object.values(metaOrLinkedinFixedColumns).forEach((col) => {
      let hasMatch = false;

      // Check each value in the col array
      for (let colItem of col) {
        if (importedColumns.has(colItem)) {
          hasMatch = true;
          break; // Stop searching once a match is found
        }
      }
      if (!hasMatch) {
        // If no item matched, push the first element
        if (col[0] !== 'Last name') {
          missingColumns.push(col[0]);
        }
      }
    });
    const expectedMissingColumns = [
      'Email address',
      'Job title',
      'Company name',
      'How many Years of work experience?',
      'In which year did you graduated?',
      'Your preferred programming language?',
    ];
    if (missingColumns.length > 0) {
      for (let i = 0; i < missingColumns?.length; i++) {
        if (expectedMissingColumns.includes(missingColumns[i])) {
          missingColumns.splice(i, 1);
          i--;
        }
      }
      if (missingColumns.length > 0) {
        setHasError(true);
        setErrorMessage(
          `These are missing columns: ${missingColumns.join(', ')}`
        );
        resetFilters();
        return false;
      }
      setImportedFileType(META_OR_LINKEDIN);
      return true;
    }
    setImportedFileType(META_OR_LINKEDIN);
    return true;
  };

  const csvToJSON = (csvData) => {
    const result = [];
    /**
     * Reasoning for `-2`:
     * 1. First `-1`: Indexing in csv files will start from 1, and after importing, the indexing will start from 0.
     * 2. second `-1`: First row always contains the column names in the csv file.
     */
    const from = 0;
    const to = csvData.length;

    for (let i = from; i < to; i += 1) {
      const jsonData = {};
      let hasUtmCampaignType = false;
      let hasProductColumn = false;

      for (let j = 0; j < columns.length; j++) {
        if ((csvData[i][j] ?? '') === '') {
          continue;
        }

        if (columns[j] === 'utm_campaign_type') {
          if (csvData[i][j] === utmCampaignType) {
            hasUtmCampaignType = true;
          }
        }

        if (columns[j] === 'product') {
          hasProductColumn = true;
        }

        jsonData[columns[j]] = csvData[i][j];
      }

      if (!hasUtmCampaignType && Object.keys(jsonData).length > 0) {
        setHasError(true);
        setErrorMessage(
          `'utm_campaign_type' field is mandatory. The accepted value is: ${utmCampaignType}.`
        );
        resetFilters();
        break;
      }

      if (!hasProductColumn) {
        setHasError(true);
        setErrorMessage(`'product' column is mandatory.`);
        resetFilters();
        break;
      }

      if (Object.keys(jsonData).length > 0) {
        result.push(jsonData);
      }
    }
    return result;
  };

  const postData = () => {
    let importedcolumns;
    setServerResponse({});
    if (importedFileType === META_OR_LINKEDIN) {
      importedcolumns = [...newLeadsColumnsOrder];
    } else {
      importedcolumns = [...columns];
    }

    if (leadsData.length === 0) {
      setHasError(true);
      setErrorMessage('No data to import');
      resetFilters();
      return;
    }

    const processedLeadsData = leadsData.map((lead) => {
      const sanitizedMobile = lead.mobile?.startsWith('p:')
        ? lead.mobile.slice(2).trim() // Remove the first 5 characters ('p:+91') and trim whitespace
        : lead.mobile;
      if (!lead.lname && lead.fname) {
        const firstSpaceIndex = lead.fname.indexOf(' '); // Find the index of the first space
        if (firstSpaceIndex !== -1) {
          return {
            ...lead,
            fname: lead.fname.substring(0, firstSpaceIndex), // Get the part before the first space
            lname: lead.fname.substring(firstSpaceIndex + 1).trim(), // Get the part after the first space and trim any whitespace
            mobile: sanitizedMobile, // Update mobile
          };
        }
        return {
          ...lead,
          mobile: sanitizedMobile, // Update mobile
        };
      }
      return lead;
    });

    setIsAddingLeads(true);

    axiosInstance
      .post(`${base_url()}/api/users/import`, {
        data: processedLeadsData,
        columns: importedcolumns,
      })
      .then((res) => {
        console.log(res.data);
        setServerResponse(res.data);

        if (
          res.data.successLeadEntries.length > 0 ||
          res.data.successSignupEntries.length > 0
        ) {
          setIsSuccess(true);
          setSuccessMessage('Added leads successfully');
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add to database'
        );
      })
      .finally(() => {
        resetFilters();
      });
  };

  const resetFilters = () => {
    setIsAddingLeads(false);
    setHasImported(false);
    setColumns([]);
    setCsvData([]);
    setLeadsData([]);
    setUnRecognizedColumns('');
    setUtmCampaignType('');
    setImportedFileType('');
  };

  const verifyImportedColumns = () => {
    const knownColumns = new Set(newLeadsColumnsOrder);
    const unknownColumns = [];

    columns.forEach((col) => {
      if (!knownColumns.has(col)) {
        unknownColumns.push(col);
      }
    });

    setUnRecognizedColumns(unknownColumns.join(', '));
    if (unknownColumns.length > 0 && importedFileType === FORMATED_CSV) {
      setOpenConfirmation(true);
    } else {
      postData();
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      {isAddingLeads && <CustomBackdrop open={isAddingLeads} />}

      {openConfirmation && unRecognizedColumns && (
        <ConfirmationModal
          {...{
            openConfirmation,
            setOpenConfirmation,
            unRecognizedColumns,
            setUnRecognizedColumns,
            postData,
          }}
        />
      )}

      <CSVReader
        onUploadAccepted={(results) => {
          if (results.data.length > 0) {
            setColumns([...results.data[0].map((col) => col.trim())]);
            setCsvData(results.data.slice(1));
            setHasImported(true);
          }
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <Stack spacing={5}>
            <Stack
              direction='row-reverse'
              spacing={2}
            >
              {!(acceptedFile && hasImported) && (
                <>
                  <Button
                    variant='contained'
                    {...getRootProps({
                      onClick: () => setUtmCampaignType('outbound'),
                    })}
                  >
                    Import outbound leads
                  </Button>
                  <Button
                    variant='contained'
                    {...getRootProps({
                      onClick: () => setUtmCampaignType('inbound'),
                    })}
                  >
                    Import inbound leads
                  </Button>
                </>
              )}

              {acceptedFile && hasImported && (
                <>
                  <Button
                    onClick={(e) => {
                      verifyImportedColumns();
                    }}
                    startIcon={<CloudUploadIcon />}
                    variant='contained'
                    sx={{ width: '150px' }}
                  >
                    upload
                  </Button>

                  <Button
                    onClick={(e) => {
                      resetFilters();
                    }}
                    variant='outlined'
                    sx={{ width: '150px', height: '40px' }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Stack>
            {acceptedFile && hasImported && (
              <>
                <div
                  style={{
                    width: 'inherit',
                    border: '1px solid #ccc',
                    height: 45,
                    lineHeight: 2.5,
                    paddingLeft: 10,
                  }}
                >
                  {acceptedFile.name}
                </div>
                <ProgressBar
                  style={{ width: 'inherit', backgroundColor: 'red' }}
                />
              </>
            )}
          </Stack>
        )}
      </CSVReader>

      {importedFileType && (
        <DisplayLeadsModal
          csvToJSON={csvToJSON}
          csvData={csvData}
          importedColumns={columns}
          importedFileType={importedFileType}
          resetFilters={resetFilters}
          leadsData={leadsData}
          setLeadsData={setLeadsData}
          utmCampaignType={utmCampaignType}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
        />
      )}

      <br />
      {Object.keys(serverResponse).length > 0 && (
        <DisplayImportLeads serverResponse={serverResponse} />
      )}
    </>
  );
}

function ConfirmationModal({
  openConfirmation,
  setOpenConfirmation,
  unRecognizedColumns,
  postData,
}) {
  return (
    <Dialog
      open={openConfirmation}
      onClose={() => {
        setOpenConfirmation(false);
      }}
    >
      <DialogContent>
        <DialogContentText>
          <span>
            We couldn't recognize these columns: <b>{unRecognizedColumns}</b>.
            <br />
            Do you still want to continue?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
            postData();
          }}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
