import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, Divider, Chip } from '@mui/material';
import {
  getDatabaseFormattedDate,
  getDatabaseFormattedDateTime,
  getFormattedTimestamp,
  getFormattedTimestampWithoutYear,
  getOffsetFromPage,
  getTimeZoneFormattedTimestamp,
} from '../../utils/common.js';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode.js';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier.jsx';
import { getCandidateStatusLable } from '../../utils/hrms/common.js';
import { Center, Text } from '@chakra-ui/react';
import Interactions from '../../pages/hrms/Interactions';
import { CurrentCandidateDataProvider } from '../../components/hrms/CurrentCandidateContext';

const CandidateFollowUpsDue = () => {
  const [due, setDue] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (params) => {
    setSelectedRowData(params.row);
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const now = getTimeZoneFormattedTimestamp(new Date());
    let url = base_url() + `/api/hrms/hrms_candidates?snoozed_until=${now}`;

    await axiosInstance
      .get(url)
      .then((res) => {
        if (res.data) {
          setDue(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching follow-up dues.'
        );
      });
  };

  const renderLabel = (row) => {
    if (!row.label) {
      return;
    }
    if (!row.label_bg_color && !row.label_font_color) {
      return (
        <Chip
          label={row.label}
          sx={{ backgroundColor: '#3f51b5', color: '#fff', margin: 'auto' }}
        />
      );
    }
    return (
      <Chip
        label={row.label}
        sx={{
          backgroundColor: row.label_bg_color,
          color: row.label_font_color,
          margin: 'auto',
        }}
      />
    );
  };

  const renderAssignee = (row) => {
    if (!row.assignee) {
      return;
    }
    return (
      <Chip
        label={row.assignee}
        sx={{ backgroundColor: '#D1C4E9', fontWeight: 500, margin: 'auto' }}
      />
    );
  };

  const getSnoozedText = (e) => {
    if (!e.value) {
      return;
    }
    return (
      <Center
        display={'inline-block'}
        margin={'auto'}
      >
        <Text
          fontSize='sm'
          display='block'
          m={1}
          background={'#FFF9C4'}
          fontWeight={500}
          p={'2px 4px'}
        >
          {getFormattedTimestampWithoutYear(new Date(e.value))}
        </Text>
      </Center>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      width: 100,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => (
        <div
          style={{ margin: 'auto' }}
        >{`#${params.row.id} ${params.row.name}`}</div>
      ),
      width: 220,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'label',
      headerName: 'Label',
      renderCell: (params) => renderLabel(params.row),
      width: 190,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      renderCell: (params) => renderAssignee(params.row),
      width: 220,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (e) => (
        <div style={{ margin: 'auto' }}>{getCandidateStatusLable(e.value)}</div>
      ),
      width: 220,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },

    {
      field: 'snoozed_until',
      headerName: 'Snoozed Until',
      renderCell: (e) => getSnoozedText(e),
      width: 190,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
  ];
  const getRowId = (row) => row.id;

  const rows = due?.map((e) => {
    return {
      id: e.id,
      name: e.name,
      assignee: e?.admin?.fname ? `${e?.admin?.fname} ${e?.admin?.lname} ` : '',
      label: e.label,
      label_bg_color: e.label_bg_color,
      label_font_color: e.label_font_color,
      mobile1: e.mobile1,
      email1: e.email1,
      status: e.status,
      snoozed_until: e.snoozed_until,
    };
  });
  return (
    <div>
      <CurrentCandidateDataProvider>
        {open && (
          <Interactions
            handleClose={handleClose}
            open={open}
            selectedRowData={selectedRowData}
          />
        )}
        <Box>
          {hasError && (
            <ErrorNotifier {...{ message: errorMessage, setHasError }} />
          )}
          <Box>
            <Typography sx={{ fontSize: '30px' }}>
              Interview Schedule
            </Typography>
            <br />
            <Divider />
          </Box>
          <br />
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              getRowId={getRowId}
              onCellClick={(params) => handleRowClick(params)}
              sx={{
                '& .MuiDataGrid-row': {
                  '&.greyed-out': {
                    backgroundColor: '#f0f0f0',
                    color: '#9e9e9e',
                  },
                },
              }}
              getRowClassName={(params) => {
                return moment(params.row.snoozed_until).format('YYYY-MM-DD') <
                  moment().format('YYYY-MM-DD')
                  ? 'greyed-out'
                  : '';
              }}
            />
          </Box>
        </Box>
      </CurrentCandidateDataProvider>
    </div>
  );
};

export default CandidateFollowUpsDue;
