import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import constants from 'utils/constants';

export const holidayOptions = [
  { value: constants.FIXED_HOLIDAY, label: 'Fixed Holiday' },
  { value: constants.HALFDAY_OFF, label: 'Halfday Off' },
  { value: constants.WEEKDAY_OFF, label: 'Weekday Off' },
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  height: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
  overflow: 'auto',
};

const AddHoliday = ({
  open,
  handleClose,
  onSubmit,
  handleUpdateHoliday,
  initialData = {},
  editModalOpen,
}) => {
  const [formData, setFormData] = useState({
    type: initialData?.type || '',
    date: initialData?.date || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (editModalOpen) {
      handleUpdateHoliday(formData);
      handleClose();
    } else {
      onSubmit(formData);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={modalStyle}>
        <Typography
          variant='h6'
          component='h2'
          textAlign='center'
          sx={{ marginBottom: '20px' }}
        >
          {initialData?.type ? 'Edit Holiday' : 'Add Holiday'}
        </Typography>
        <FormControl
          fullWidth
          sx={{ marginBottom: '20px' }}
        >
          <InputLabel id='holiday-name-label'>Holiday Name</InputLabel>
          <Select
            labelId='holiday-name-label'
            id='holiday-name'
            name='type'
            value={formData?.type}
            onChange={handleChange}
          >
            {holidayOptions?.map((option) => (
              <MenuItem
                key={option?.value}
                value={option?.value}
              >
                {option?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{ marginBottom: '20px' }}
        >
          <TextField
            required
            type='date'
            label='Date'
            name='date'
            value={formData?.date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default AddHoliday;
