import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { fetchTelecmiUserData } from 'utils/telecmi_common';

const TelecmiDetailsModal = ({
  open,
  onClose,
  onSave,
  onDelete,
  isEditing,
  selectedData,
}) => {
  const [phoneNo, setPhoneNo] = useState('');
  const [virtualNumber, setVirtualNumber] = useState('');
  const [userId, setUserId] = useState('');
  const [errors, setErrors] = useState({
    phoneNo: '',
    virtualNumber: '',
    userId: '',
    form: '',
  });

  useEffect(() => {
    if (isEditing) {
      fetchTelecmiData();
    }
  }, []);

  const fetchTelecmiData = async () => {
    const data = await fetchTelecmiUserData(selectedData?.id);
    if (data) {
      const { phone, virtual_number, user_id } = data;
      setPhoneNo(phone);
      setVirtualNumber(virtual_number);
      setUserId(user_id);
    }
  };

  const validateUserId = (value) => {
    const userIdPattern = /^\d{4}_\d{8}$/;
    return userIdPattern.test(value);
  };

  const validatePhoneNumber = (value) => {
    return value && value.length === 10 && /^\d+$/.test(value);
  };

  const handleSave = () => {
    // Reset all errors
    const newErrors = {
      phoneNo: '',
      virtualNumber: '',
      userId: '',
      form: '',
    };

    // Validate all fields when creating new entry
    if (!isEditing) {
      if (!phoneNo) newErrors.phoneNo = 'Phone number is required';
      if (!virtualNumber)
        newErrors.virtualNumber = 'Virtual number is required';
      if (!userId) newErrors.userId = 'User ID is required';
    }

    // Validate format if fields are filled
    if (phoneNo && !validatePhoneNumber(phoneNo)) {
      newErrors.phoneNo = 'Please enter a valid 10-digit phone number';
    }

    if (virtualNumber && !validatePhoneNumber(virtualNumber)) {
      newErrors.virtualNumber = 'Please enter a valid 10-digit virtual number';
    }

    if (userId && !validateUserId(userId)) {
      newErrors.userId =
        'User ID format should be "0000_00000000" (4 digits_8 digits)';
    }

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some((error) => error !== '');
    setErrors(newErrors);

    if (hasErrors) {
      return;
    }

    // Create payload with validated data
    const payload = {
      phoneNo: Number(phoneNo),
      virtualNumber: Number(virtualNumber),
      userId,
      email: selectedData?.email,
    };

    onSave(payload);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 0,
      }}
      PaperProps={{
        style: {
          minWidth: '90%',
          maxWidth: '100%',
        },
      }}
    >
      <DialogTitle
        style={{
          textAlign: 'center',
          position: 'relative',
          margin: '15px 0 -15px 0',
        }}
      >
        {isEditing ? 'Edit TeleCMI Details' : 'Add TeleCMI Details'}
        <IconButton
          aria-label='close'
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          transform: 'scale(0.9)',
          transformOrigin: 'center',
        }}
      >
        <TextField
          label='Enter Phone No'
          fullWidth
          margin='normal'
          value={phoneNo}
          error={!!errors.phoneNo}
          helperText={errors.phoneNo}
          onChange={(e) => setPhoneNo(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
          }}
          required={!isEditing}
        />
        <TextField
          label='Enter Virtual Number'
          fullWidth
          margin='normal'
          value={virtualNumber}
          error={!!errors.virtualNumber}
          helperText={errors.virtualNumber}
          onChange={(e) => setVirtualNumber(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
          }}
          required={!isEditing}
        />
        <TextField
          label='Enter User ID'
          fullWidth
          margin='normal'
          value={userId}
          error={!!errors.userId}
          helperText={
            errors.userId || 'Format: 0000_00000000 (4 digits_8 digits)'
          }
          onChange={(e) => setUserId(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
          }}
          required={!isEditing}
        />

        {errors.form && (
          <Typography
            variant='body2'
            color='error'
            style={{ marginTop: '10px', textAlign: 'center' }}
          >
            {errors.form}
          </Typography>
        )}

        {isEditing && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Button
              variant='outlined'
              color='error'
              startIcon={<DeleteIcon />}
              onClick={() => onDelete(selectedData)}
              style={{ width: '15rem' }}
            >
              Revoke TeleCMI access
            </Button>
          </div>
        )}

        {isEditing && (
          <p style={{ color: 'red', marginTop: '8px', fontSize: '0.8rem' }}>
            *Note: If you delete this user, the user would not be able to access
            TeleCMI.
          </p>
        )}

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button
            variant='outlined'
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            fullWidth
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default TelecmiDetailsModal;
