import {
  getDatabaseFormattedDateTime,
  getTimeZoneFormattedTimestamp,
} from '../../utils/common';

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// export const lastXDays = (x) => {
//   const currentDate = new Date();
//   currentDate.setDate(currentDate.getDate() - x);
//   const toDate = getCurrentDate(); // Current date
//   const fromDate = currentDate.toISOString().split('T')[0]; // X days ago
//   return { from: fromDate, to: toDate };
// };

export const getDateRangeForLastNDays = (days) => {
  const currentDate = new Date();

  if (days === 0) {
    const today = currentDate.toISOString().split('T')[0];
    return { from: today, to: today };
  }

  currentDate.setDate(currentDate.getDate() - days);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const fromDate = currentDate.toISOString().split('T')[0];
  const toDate = yesterday.toISOString().split('T')[0];

  return { from: fromDate, to: toDate };
};

export const lastMonth = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );
  return {
    from: firstDayOfMonth.toISOString().split('T')[0],
    to: lastDayOfMonth.toISOString().split('T')[0],
  };
};
export const lastQuarter = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3) + 1;
  const firstMonthOfQuarter = (currentQuarter - 1) * 3;
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    firstMonthOfQuarter + 2,
    0
  );
  const firstDayOfQuarter = new Date(
    currentDate.getFullYear(),
    firstMonthOfQuarter,
    1
  );

  return {
    from: firstDayOfQuarter.toISOString().split('T')[0],
    to: lastDayOfMonth.toISOString().split('T')[0],
  };
};

export const lastYear = () => {
  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastDayOfYear = new Date(currentDate.getFullYear() - 1, 11, 31);
  return {
    from: firstDayOfYear.toISOString().split('T')[0],
    to: lastDayOfYear.toISOString().split('T')[0],
  };
};

export const allShortcuts = {
  today: getDateRangeForLastNDays(0),
  yesterday: {
    ...getDateRangeForLastNDays(1),
    to: getDateRangeForLastNDays(1).from,
  },
  last_week: getDateRangeForLastNDays(7),
  last_14_days: getDateRangeForLastNDays(14),
  last_28_days: getDateRangeForLastNDays(28),
  last_month: lastMonth(),
  last_quarter: lastQuarter(),
  last_year: lastYear(),
};
