import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import HolidaysTable from './HolidaysTable';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import AddHoliday from './AddHoliday';
import {
  fetchAllHolidays,
  splitByUnderscoresAndCapitalize,
} from 'utils/common';
import axiosInstance from 'apis/axiosInstance';
import HolidaySummary from './HolidaysSummary';
import constants from 'utils/constants';

export default function Holidays() {
  const [allHolidays, setAllHolidays] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldFetchHolidays, setShouldFetchHolidays] = useState(false);
  const [daySummary, setDaySummary] = useState({
    weekOffDays: '',
    halfDayOffDays: '',
  });

  // Fetch holidays on component mount or when `shouldFetchHolidays` changes
  useEffect(() => {
    fetchAllHolidays(setAllHolidays, setHasError, setErrorMessage);
  }, [shouldFetchHolidays]);

  useEffect(() => {
    if (allHolidays.length > 0) {
      const currentDate = new Date(); // Current date
      const weekOffDays = new Set();
      const halfDayOffDays = new Set();

      allHolidays.forEach((holiday) => {
        const holidayDate = new Date(holiday.date);
        const dayName = holidayDate.toLocaleString('en-US', {
          weekday: 'long',
        });

        // Check if the holiday date is today or in the future
        if (holidayDate >= currentDate) {
          if (holiday.type === constants.WEEKDAY_OFF) {
            weekOffDays.add(dayName);
          }
          if (holiday.type === constants.HALFDAY_OFF) {
            halfDayOffDays.add(dayName);
          }
        }
      });

      setDaySummary({
        weekOffDays:
          weekOffDays.size > 0
            ? Array.from(weekOffDays).join(', ') // Convert Set to a string
            : 'Not selected from current date onward',
        halfDayOffDays:
          halfDayOffDays.size > 0
            ? Array.from(halfDayOffDays).join(', ') // Convert Set to a string
            : 'Not selected from current date onward',
      });
    }
  }, [allHolidays]);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const validate = (data) => {
    if (!data?.type) {
      toast.error('Holiday Name is required');
      return false;
    }
    if (!data?.date) {
      toast.error('Date is required');
      return false;
    }
    return true;
  };

  const createHoliday = async (data) => {
    try {
      const response = await axiosInstance.post('/api/v1/holiday', data);
      toast.success(response.data.message || 'Holiday added successfully.');
      setShouldFetchHolidays(!shouldFetchHolidays);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred.';
      toast.error(`Error in adding holiday details: ${errorMessage}`);
    } finally {
      handleClose();
    }
  };

  const handleSubmit = (data) => {
    if (!validate(data)) return;
    createHoliday(data);
  };

  const handleDelete = async (data) => {
    try {
      if (!validate(data)) return;

      const { type, date } = data;
      const confirmDelete = window.confirm(
        `Are you sure you want to delete the holiday of type ${splitByUnderscoresAndCapitalize(
          type
        )} on date ${date}?`
      );

      if (!confirmDelete) return;

      const response = await axiosInstance.delete('/api/v1/holiday', {
        data: { type, date },
      });
      setShouldFetchHolidays(!shouldFetchHolidays);
      toast.success(response?.data?.message || 'Holiday deleted successfully.');
    } catch (error) {
      toast.error(
        `An error occurred while deleting the holiday: ${error?.response?.data?.message}`
      );
    }
  };

  const updateHoliday = async (data) => {
    try {
      const { newType, newDate } = data;

      if (!validate(data)) return;

      if (!newType && !newDate) {
        toast.error(
          'At least one updated field (new type or new date) is required.'
        );
        return;
      }

      const response = await axiosInstance.patch('/api/v1/holiday', data);

      setShouldFetchHolidays(!shouldFetchHolidays);
      toast.success(response?.data?.message || 'Holiday updated successfully.');
    } catch (error) {
      toast.error(
        `An error occurred while updating the holiday: ${error?.response?.data?.message}`
      );
    }
  };

  const changeHolidayOrHalfday = async (data) => {
    try {
      const { day, type, title } = data;

      if (!day) {
        toast.error(
          `An error occurred while updating: ${title} can not be set more then one `
        );
        return;
      }
      const dataToSend = {
        type,
        day: data?.day,
      };
      const response = await axiosInstance.patch(
        '/api/v1/holiday/fixed-schedule',
        dataToSend
      );

      setShouldFetchHolidays(!shouldFetchHolidays);
      toast.success(
        response?.data?.message || ` ${title} changed successfully.`
      );
    } catch (error) {
      toast.error(
        `An error occurred while changing the holiday: ${error?.response?.data?.message}`
      );
    }
  };

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 2,
        }}
      >
        <Button
          variant='contained'
          onClick={handleOpen}
        >
          Add Holiday
        </Button>
      </Box>
      {daySummary?.halfDayOffDays && (
        <HolidaySummary
          title='Half Day'
          type={constants.HALFDAY_OFF}
          selectedDays={daySummary.halfDayOffDays}
          onSave={changeHolidayOrHalfday}
        />
      )}
      {daySummary?.weekOffDays && (
        <HolidaySummary
          title='Week Off'
          type={constants.WEEKDAY_OFF}
          selectedDays={daySummary.weekOffDays}
          onSave={changeHolidayOrHalfday}
        />
      )}

      {allHolidays.length > 0 && (
        <HolidaysTable
          allHolidays={allHolidays}
          handleDelete={handleDelete}
          updateHoliday={updateHoliday}
          onSubmit={handleSubmit}
        />
      )}

      <AddHoliday
        open={isModalOpen}
        handleClose={handleClose}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
