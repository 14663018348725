import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCampaignModal from './AddCampaignModal';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode';
import Input from '@mui/material/Input';
import NumericFilter from './NumericFilter';
import { styled } from '@mui/system';
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Stack,
} from '@mui/material';
import CampaignsTable from './CampaignsTable';
import { useDispatch } from 'react-redux';
import { fetchCampaigns } from 'features/campaigns/campaignsThunk';
import { useSelector } from 'react-redux';
import { setPage } from 'features/campaigns/campaignSlice';
const page_limit = 50;
const initialState = {
  sort: '',
  source: '',
  medium: '',
  status: '',
  start_date: '',
  end_date: '',
  created_by: '',
};
const CustomOutlinedButton = styled(Button)(({ isSelected }) => ({
  color: isSelected ? '#1976d2' : 'black',
  borderColor: isSelected ? '#1976d2' : 'black',
  '&:hover': {
    borderColor: isSelected ? '#1565c0' : 'black',
  },
}));

const Campaign = () => {
  const [selectedButton, setSelectedButton] = useState('all');
  const [expanded, setExpanded] = useState(true);
  const [openCampaignModal, setOpenCampaignModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState(initialState);
  const [tableData, setTableData] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [marketingidnames, setMarketingIdNames] = useState([]);
  const dispatch = useDispatch();
  const [numericFilterCriteria, setNumericFilterCriteria] = useState({
    operator: '',
    value: null,
  });

  const {
    campaigns,
    loading,
    page,
    totalCampaigns,
    utmUniqueSource,
    statusUnique,
    utmUniqueMedium,
    campaignName,
  } = useSelector((state) => state.campaigns);

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(setPage(0));
    setFilters({ ...filters, [name]: value });
  };

  const handleFilterByCost = () => {
    dispatch(setPage(0));
    setIsFilterVisible(!isFilterVisible);
  };

  const handleAdminFilterChange = (event) => {
    const value = event.target.value;
    dispatch(setPage(0));
    setFilters({ ...filters, created_by: value });
  };

  const handleNumericFilter = (filterCriteria) => {
    dispatch(setPage(0));
    setNumericFilterCriteria(filterCriteria);
  };

  const handleNumericFilterClose = () => {
    dispatch(setPage(0));
    setNumericFilterCriteria({
      operator: '',
      value: null,
    });

    setIsFilterVisible(false);
  };
  const handleNewCampaignModal = () => {
    setOpenCampaignModal(!openCampaignModal);
  };

  const handleOpenAddModal = () => {
    setOpenCampaignModal(true);
    setSelectedCampaign(null);
  };

  const updateCampaignApiCall = (data) => {
    setSelectedCampaign(data);
    setOpenCampaignModal(true);
  };
  const fetchMarketingRoleAdminIds = async () => {
    try {
      const role = 'marketing';
      const response = await axiosInstance.get(
        `/api/admins/get-admins-by-role?role=${role}`
      );
      const uniqueMarketingIds = response.data;
      const adminNames = uniqueMarketingIds.map((entry) => ({
        id: entry.id,
        name: entry.fullname,
      }));
      setMarketingIdNames(adminNames);
    } catch (error) {
      setHasError(true);
      setErrorMessage(error, 'Error fetching marketing IDs');
    }
  };

  const fetchCampaignsData = () => {
    dispatch(
      fetchCampaigns({ page, page_limit, filters, numericFilterCriteria })
    );
  };

  // Update useEffect to include page in dependencies
  useEffect(() => {
    fetchCampaignsData();
    fetchMarketingRoleAdminIds();
    return () => {};
  }, [filters, numericFilterCriteria]);

  // Handle page change
  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
    dispatch(
      fetchCampaigns({
        page: newPage,
        page_limit,
        filters,
        numericFilterCriteria,
      })
    );
  };

  const handleButtonSelect = (buttonName) => {
    const validButtonClicks = [
      'all',
      'funnel',
      'cost',
      'quality leads',
      'frequency',
    ];
    const lowerCaseButtonName = buttonName.toLowerCase();

    setSelectedButton(lowerCaseButtonName);

    if (!validButtonClicks.includes(lowerCaseButtonName)) {
      setTableData(tableData);
    }
  };
  const NumericFilterOperators = {
    LESS_THAN_OR_EQUAL: '<=',
    GREATER_THAN_OR_EQUAL: '>=',
  };
  const buttonNames = ['All', 'Funnel', 'Cost', 'Frequency', 'QualityLeads'];

  return (
    <Stack
      width='100%'
      height='100%'
      spacing={2}
      sx={{
        mt: 5,
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button
          variant='contained'
          size='medium'
          onClick={handleOpenAddModal}
          sx={{
            marginLeft: 'auto',
            borderRadius: '0.8rem',
            marginBottom: '0.5rem',
          }}
        >
          Create New Campaign
        </Button>
      </Box>

      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            direction='row'
            alignItems='flex-start'
            justifyContent='flex-start'
            sx={{
              position: 'relative',
              marginTop: '16px',
            }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='source'
                size='small'
                fullWidth
              >
                <InputLabel id='sort_by'>Source</InputLabel>
                <Select
                  name='source'
                  label='sort_by'
                  labelId='sort_by'
                  onChange={handleChange}
                >
                  <MenuItem value=''>None</MenuItem>
                  {utmUniqueSource?.map((source) => (
                    <MenuItem
                      key={source}
                      value={source}
                    >
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='source'
                size='small'
                fullWidth
              >
                <Autocomplete
                  options={campaignName}
                  getOptionLabel={(option) => option}
                  value={filters.utm_campaign || ''}
                  onChange={(_, newValue) => {
                    handleChange({
                      target: { name: 'utm_campaign', value: newValue || '' },
                    });
                  }}
                  style={{ width: '100%', fontSize: '16px' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Campaign'
                      size='small'
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='created_by'
                size='small'
                fullWidth
              >
                <InputLabel id='created_by'>Admin</InputLabel>
                <Select
                  name='created_by'
                  label='sort_by'
                  labelId='sort_by'
                  value={filters.created_by}
                  onChange={handleAdminFilterChange}
                >
                  <MenuItem value=''>None</MenuItem>
                  {marketingidnames.map((admin, index) => (
                    <MenuItem
                      key={admin.id}
                      value={admin.id}
                    >
                      {admin.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='status'
                size='small'
                fullWidth
              >
                <InputLabel id='sort_by'>Status</InputLabel>
                <Select
                  name='status'
                  label='sort_by'
                  labelId='sort_by'
                  onChange={handleChange}
                >
                  <MenuItem value=''>None</MenuItem>
                  {statusUnique?.map((source) => (
                    <MenuItem
                      key={source}
                      value={source}
                    >
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='medium'
                size='small'
                fullWidth
              >
                <InputLabel id='medium_by'>Medium</InputLabel>
                <Select
                  name='medium'
                  label='medium_by'
                  labelId='medium_by'
                  onChange={handleChange}
                >
                  <MenuItem value=''>None</MenuItem>
                  {utmUniqueMedium?.map((source) => (
                    <MenuItem
                      key={source}
                      value={source}
                    >
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='start_date'
                size='small'
                fullWidth
              >
                <InputLabel
                  htmlFor='start_date'
                  style={{ marginBottom: '5px', fontSize: '14px' }}
                >
                  Start Date
                </InputLabel>
                <Input
                  type='date'
                  name='start_date'
                  value={filters.start_date}
                  onChange={handleChange}
                  style={{ fontSize: '14px', paddingTop: '6px' }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='end_date'
                size='small'
                fullWidth
              >
                <InputLabel
                  id='end_date'
                  style={{ marginBottom: '3px', fontSize: '14px' }}
                >
                  End Date
                </InputLabel>
                <Input
                  type='date'
                  name='end_date'
                  value={filters.end_date}
                  onChange={handleChange}
                  style={{ fontSize: '14px', paddingTop: '6px' }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <InputLabel
                onClick={handleFilterByCost}
                style={{
                  border: '1px solid #bab7b6',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginBottom: '10px',
                }}
              >
                Cost(Rs)
              </InputLabel>

              {isFilterVisible && (
                <NumericFilter
                  operators={Object.values(NumericFilterOperators)}
                  onFilter={handleNumericFilter}
                  onClose={handleNumericFilterClose}
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {openCampaignModal && (
        <AddCampaignModal
          isOpen={openCampaignModal}
          onCloseCampaignModal={handleNewCampaignModal}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          selectedCampaign={selectedCampaign}
          setTableData={setTableData}
          setSelectedCampaign={setSelectedCampaign}
        />
      )}

      <Grid
        container
        spacing={2}
      >
        {buttonNames.map((btnName, index) => (
          <Grid
            item
            key={index}
          >
            <CustomOutlinedButton
              variant='outlined'
              isSelected={selectedButton === btnName.toLowerCase()}
              onClick={() => handleButtonSelect(btnName)}
            >
              {btnName}
            </CustomOutlinedButton>
          </Grid>
        ))}
      </Grid>

      <CampaignsTable
        loading={loading}
        tableData={campaigns || tableData}
        fetchCampaigns={() =>
          dispatch(
            fetchCampaigns({ page, page_limit, filters, numericFilterCriteria })
          )
        }
        updateCampaignApiCall={(data) => updateCampaignApiCall(data)}
        selectedButton={selectedButton}
        setTableData={setTableData}
        page={page}
        onPageChange={handlePageChange}
        totalRows={totalCampaigns}
        perPage={page_limit}
      />
    </Stack>
  );
};
export default Campaign;
