import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Input,
  FormControl,
  Stack,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  Typography,
  Divider,
} from '@mui/material';
import {
  currentAdminIsAdmin,
  getDatabaseFormattedDate,
  getFormattedTimestamp,
  currentAdminIsTa,
  currentAdminIsHr,
  getFormatTimestamp,
} from '../../utils/common.js';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';

import AdminsSearchDropdown from '../../components/admins/AdminSearch';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../components/Mode';
import axiosInstance from 'apis/axiosInstance';
import { addRecencyText } from '../../utils/AddRecencyText.js';
import { getCandidateStatusLable } from '../../utils/hrms/common.js';

const HRMSRecentInteraction = () => {
  const getDefaultParams = () => {
    const date_str = getDatabaseFormattedDate(new Date());
    return {
      from: date_str,
      to: date_str,
    };
  };
  const [params, setParams] = useState(getDefaultParams());
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultDate, setDefaultDate] = useState(new Date());

  const defaultDateIST = defaultDate.toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata', // IST timezone
  });

  useEffect(() => {
    fetchData();
  }, [params]);

  const fetchData = () => {
    let url = base_url() + '/api/hrms/hrms_candidates/interactions';

    axiosInstance
      .get(url, { params })
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching recent interactions'
        );
      });
  };
  const updateParam = (e, param_name) => {
    let value = e.target.value;
    let paramsClone = Object.assign({}, params);

    // Check if the value is an array and if it's empty
    if (Array.isArray(value) && value.length === 0) {
      // Do not update params if the array is empty
      return;
    }

    if (value === '') {
      delete paramsClone[param_name];
    } else {
      paramsClone[param_name] = value;
    }

    setParams(paramsClone);
  };

  const filters = () => {
    return (
      <>
        <FormGroup
          row
          style={{ float: 'right', marginBottom: '22px', paddingRight: '10px' }}
        >
          <Stack
            direction='row'
            spacing='10px'
            style={{ width: '100%' }}
          >
            <FormControl
              id='from'
              style={{ width: '180px' }}
            >
              <InputLabel id='from-label'>From</InputLabel>
              <Input
                type='text'
                placeholder='From'
                name='from'
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                onChange={(e) => {
                  updateParam(e, 'from');
                }}
                defaultValue={getDatabaseFormattedDate(new Date())}
              />
            </FormControl>

            <FormControl
              id='to'
              style={{ width: '180px' }}
            >
              <InputLabel id='to-label'>To</InputLabel>
              <Input
                type='text'
                placeholder='To'
                name='to'
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                defaultValue={getDatabaseFormattedDate(new Date())}
                onChange={(e) => {
                  updateParam(e, 'to');
                }}
              />
            </FormControl>
            <AdminsSearchDropdown
              label='Assignee'
              value={params.admin}
              onChangeHandler={(newValue) => {
                let array = newValue.split(',');
                updateParam(
                  {
                    target: {
                      name: 'assignee',
                      value: array || [],
                    },
                  },
                  'admin'
                );
              }}
              sx={{
                width: '100%',
              }}
              size='small'
              disabled={
                !currentAdminIsAdmin() &&
                !currentAdminIsTa() &&
                !currentAdminIsHr()
              }
              showCurrentAdmin={
                !currentAdminIsAdmin() &&
                !currentAdminIsTa() &&
                !currentAdminIsHr()
              }
              showAllOption={
                currentAdminIsAdmin() ||
                currentAdminIsTa() ||
                currentAdminIsHr()
              }
            />
          </Stack>
        </FormGroup>
      </>
    );
  };
  const getRowId = (row) => row.interaction_id;
  const columns = [
    {
      field: 'interaction_id',
      headerName: 'interaction_id',
      width: 100,
      hide: true,
    },
    {
      field: 'candidate_id',
      headerName: 'Candidate Id',
      width: 110,
    },
    {
      field: 'candidate_name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'candidate_status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <div>{getCandidateStatusLable(params.row.candidate_status)}</div>
      ),
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      width: 150,
      renderCell: (params) => (
        <div>
          {params.row.admin_fname} {params.row.admin_lname}
        </div>
      ),
    },
    {
      field: 'candidate_label',
      headerName: 'Label',
      width: 150,
    },
    {
      field: 'interaction_notes',
      headerName: 'Last Interaction',
      renderCell: (params) => (
        <Typography
          align='center'
          variant='body2'
          sx={{ backgroundColor: '#ebf8ff', padding: '2px', margin: 1 }}
        >
          {params.row.interaction_notes}
        </Typography>
      ),
      width: 500,
    },
    {
      field: 'interaction_created_at',
      headerName: 'Last Interaction on',
      renderCell: (params) => (
        <Typography
          align='center'
          variant='body2'
          sx={{ backgroundColor: '#ebf8ff', padding: '2px', margin: 1 }}
        >
          {getFormatTimestamp(new Date(params.row.interaction_created_at))}
        </Typography>
      ),
      width: 200,
    },
  ];

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Box
        component='div'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography
          variant='h5'
          component='div'
          gutterBottom
          style={{ marginRight: 10 }}
        >
          Recent Interactions ({data.length})
        </Typography>
      </Box>
      <Divider />

      <Stack>
        <Box>
          <br />
          <Box>{filters()}</Box>
          <br />
        </Box>
        <Box>
          <DataGrid
            rows={data}
            columns={columns}
            autoHeight
            getRowId={getRowId}
          />
        </Box>
      </Stack>
    </>
  );
};

export default HRMSRecentInteraction;
