import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Typography, IconButton, Divider, Grid } from '@mui/material';
import { capitalize } from '../../utils/common';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';

function Pool({
  freshleads,
  tapLeads,
  reactivatedleads,
  tag,
  groupByUtmSource,
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const itemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',
  };

  const handleSuccessCopy = (message) => {
    setSuccessMessage(message);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
  };

  const handleFreshLeadsCopy = () => {
    const freshleadsIds = freshleads.flatMap((lead) => lead.lead_ids);
    navigator.clipboard
      .writeText(
        freshleadsIds.length !== 0
          ? freshleadsIds.join(',')
          : 'No fresh lead ids'
      )
      .then(() => {
        handleSuccessCopy('Copied');
      });
  };

  const handleReactivatedLeadsCopy = () => {
    const reactivatedLeads = reactivatedleads.flatMap((lead) => lead.lead_ids);
    navigator.clipboard
      .writeText(
        reactivatedLeads.length !== 0
          ? reactivatedLeads.join(',')
          : 'No reactivated lead ids'
      )
      .then(() => {
        handleSuccessCopy('Copied');
      });
  };

  const handleTapLeadsCopy = () => {
    const tapLeadIds = tapLeads.flatMap((lead) => lead.lead_ids);
    navigator.clipboard
      .writeText(
        tapLeadIds.length !== 0 ? tapLeadIds.join(',') : 'No tap lead ids'
      )
      .then(() => {
        handleSuccessCopy('Copied');
      });
  };

  const handleAllLeadsCopy = () => {
    const allLeadIds = [
      ...freshleads.flatMap((lead) => lead.lead_ids),
      ...reactivatedleads.flatMap((lead) => lead.lead_ids),
      ...tapLeads.flatMap((lead) => lead.lead_ids),
    ];
    navigator.clipboard
      .writeText(allLeadIds.length > 0 ? allLeadIds.join(',') : 'No Lead Ids')
      .then(() => {
        handleSuccessCopy('Copied');
      });
  };

  const handleFreshLeadsCopyFromLabel = (leadIdsArray) => {
    const chunkSize = 100; // Define a chunk size to copy at a time
    const chunks = [];

    for (let i = 0; i < leadIdsArray.length; i += chunkSize) {
      chunks.push(leadIdsArray.slice(i, i + chunkSize).join(','));
    }

    // Join chunks with newline for clarity
    navigator.clipboard.writeText(chunks.join('\n')).then(() => {
      handleSuccessCopy('Copied');
    });
  };

  const handleGroupByUtmSourceCopy = (leads) => {
    const leadIds = leads.map((lead) => lead.lead_id);
    navigator.clipboard.writeText(leadIds.join(', ')).then(() => {
      handleSuccessCopy('Copied');
    });
  };

  const freshLeadsCount =
    freshleads.length > 0 ? parseInt(freshleads[0]['lead_ids'].length) : 0;
  const tapLeadsCount =
    tapLeads.length > 0 ? parseInt(tapLeads[0]['lead_ids'].length) : 0;
  const reactivatedLeadsCount =
    reactivatedleads.length > 0
      ? parseInt(reactivatedleads[0]['lead_ids'].length)
      : 0;
  const totalLeads = freshLeadsCount + tapLeadsCount + reactivatedLeadsCount;

  return (
    <>
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Grid
        container
        spacing={2}
        style={{ width: '100%', marginBottom: '1%' }}
      >
        {/* First Box */}
        <Grid
          item
          xs={12}
          md={4}
        >
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: '500px',
              border: '0.1px solid lightgrey',
              borderRadius: '5px',
              padding: '15px',
              overflow: 'hidden',
              marginBottom: '20px',
            }}
          >
            <Typography
              variant='h6'
              sx={{ marginBottom: '10px' }}
            >
              Type
            </Typography>
            <Divider />
            <Grid
              container
              spacing={2}
              style={itemStyle}
            >
              <Grid
                item
                xs={8}
              >
                <Typography sx={{ color: '#6b6b6b' }}>Fresh Leads:</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: 'right' }}
              >
                <Typography sx={{ color: '#6b6b6b', marginRight: '3px' }}>
                  {freshLeadsCount}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              ></Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={handleFreshLeadsCopy}>
                  <ContentCopyIcon sx={{ height: '17px', color: '#bdbdbd' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={itemStyle}
            >
              <Grid
                item
                xs={8}
              >
                <Typography sx={{ color: '#6b6b6b' }}>
                  Reactivated Leads:
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: 'right' }}
              >
                <Typography sx={{ color: '#6b6b6b', marginRight: '3px' }}>
                  {reactivatedLeadsCount}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              ></Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={handleReactivatedLeadsCopy}>
                  <ContentCopyIcon sx={{ height: '17px', color: '#bdbdbd' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={itemStyle}
            >
              <Grid
                item
                xs={8}
              >
                <Typography sx={{ color: '#6b6b6b' }}>Tapped Leads:</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: 'right' }}
              >
                <Typography sx={{ color: '#6b6b6b', marginRight: '3px' }}>
                  {tapLeadsCount}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              ></Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={handleTapLeadsCopy}>
                  <ContentCopyIcon sx={{ height: '17px', color: '#bdbdbd' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
            <Grid
              container
              spacing={1}
              style={itemStyle}
            >
              <Grid
                item
                xs={8}
              >
                <Typography>
                  <b>All Leads:</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: 'right' }}
              >
                <Typography sx={{ marginRight: '3px' }}>
                  <b>{totalLeads}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              ></Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={handleAllLeadsCopy}>
                  <ContentCopyIcon sx={{ height: '17px', color: '#bdbdbd' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Second Box */}
        <Grid
          item
          xs={12}
          md={4}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
              border: '0.1px solid lightgrey',
              borderRadius: '5px',
              padding: '15px',
              overflow: 'hidden',
              marginBottom: '20px',
              overflowY: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Typography
                variant='h6'
                x={{ marginLeft: '20px' }}
              >
                Tag
              </Typography>
              <Typography
                variant='h6'
                sx={{ marginLeft: '20px', paddingRight: '60px' }}
              >
                IDs
              </Typography>
            </div>
            <Divider />
            {tag?.length > 0 &&
              tag.map((item, index) => {
                return (
                  <Grid
                    container
                    spacing={1}
                    style={itemStyle}
                    key={index}
                  >
                    <Grid
                      item
                      xs={8}
                    >
                      <Typography sx={{ color: '#6b6b6b' }}>
                        {item.TAG ? <>{item.TAG}</> : 'No Tag'}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ textAlign: 'right' }}
                    >
                      <Typography sx={{ color: '#6b6b6b', marginRight: '3px' }}>
                        {item.lead_ids.length}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          handleFreshLeadsCopyFromLabel(item.lead_ids)
                        }
                      >
                        <ContentCopyIcon
                          sx={{ height: '17px', color: '#bdbdbd' }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
          </Box>
        </Grid>

        {/* Third Box */}
        <Grid
          item
          xs={12}
          md={4}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
              border: '0.1px solid lightgrey',
              borderRadius: '5px',
              padding: '15px',
              overflow: 'hidden',
              marginBottom: '20px',
            }}
          >
            <Typography
              variant='h6'
              sx={{ marginBottom: '10px' }}
            >
              Sources
            </Typography>
            <Divider />
            {groupByUtmSource.map((group, index) => (
              <Grid
                container
                spacing={2}
                style={itemStyle}
                key={index}
              >
                <Grid
                  item
                  xs={8}
                >
                  <Typography sx={{ color: '#6b6b6b' }}>
                    {group.field
                      ? capitalize(group.field)
                      : 'Unknown UTM Sources'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ textAlign: 'right' }}
                >
                  <Typography sx={{ color: '#6b6b6b', marginRight: '3px' }}>
                    {group.leads.length}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                ></Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    onClick={() => handleGroupByUtmSourceCopy(group.leads)}
                  >
                    <ContentCopyIcon
                      sx={{ height: '17px', color: '#bdbdbd' }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Pool;
