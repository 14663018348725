import React, { useEffect, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import constants from '../../utils/constants';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Box,
  FormControl,
  Chip,
  Typography,
  IconButton,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import moment from 'moment';
import { getCurrentAdminEmail, getCurrentAdminId } from '../../utils/common';
import AddCircleIcon from '@mui/icons-material/AddCircle';
const ScheduleMeetingPopUp = ({
  setOpenScreeningScheduleModal,
  openScreeningScheduleModal,
  openScheduleMeetingPopup,
  setOpenScheduleMeetingPopup,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  leadScheduledStatusActivity,
  getLeadScheduledActivitiesMetadata,
  updateLeadDetailState,
  refreshLeadContext,
  setMetadataForScreeningCallSchedule,
  currentLead,
}) => {
  const [meetingDetails, setMeetingDetails] = useState({
    summary: '',
    description: '',
    start_datetime: '',
  });

  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [email, setEmail] = useState('');
  const [attendees, setAttendees] = useState([
    currentLead?.email,
    constants?.HEYCOACH_TEAM_EMAIL_ID,
    getCurrentAdminEmail(),
  ]);

  let gmtTimeForStartTime = moment.utc(
    leadScheduledStatusActivity?.metadata?.start_time,
    'YYYY-MM-DD HH:mm'
  );

  const startTimeISTConversion = leadScheduledStatusActivity?.metadata
    ?.start_time
    ? moment(gmtTimeForStartTime).local().format('YYYY-MM-DD HH:mm')
    : null;

  useEffect(() => {
    setSelectedAdmin(leadScheduledStatusActivity?.metadata?.admin_id ?? null);
    setStartTime(startTimeISTConversion);
  }, [leadScheduledStatusActivity]);

  const handleAddAttendee = () => {
    if (email) {
      // Basic email validation
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setAttendees([...attendees, email]);
        setEmail('');
      } else {
        setHasError(true);
        setErrorMessage('Please enter a valid email address.');
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === constants.ENTER) {
      handleAddAttendee();
    }
  };

  const handleDelete = (emailToDelete) => () => {
    setAttendees((attendees) =>
      attendees.filter((email) => {
        const emailLabel = typeof email === 'object' ? email.email : email;
        return emailLabel !== emailToDelete;
      })
    );
  };

  const handleScheduleMeetingPopUp = () => {
    setOpenScheduleMeetingPopup(false);
  };

  const handleMeetingDetailsChange = (e) => {
    setMeetingDetails({
      ...meetingDetails,
      [e.target.name]: e.target.value,
    });
  };

  function formatDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    let formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDateTime;
  }

  const handleSubmit = async () => {
    let attendeesArray = [];
    if (attendees.length > 0) {
      attendeesArray = attendees.map((email) => {
        if (typeof email === 'object') {
          return email.email;
        } else {
          return email;
        }
      });
    }
    const data = {
      summary: meetingDetails.summary,
      description: meetingDetails.description,
      start_datetime: meetingDetails.start_datetime,
      attendees: attendeesArray,
      id: currentLead?.id,
      timeZone: 'Asia/Kolkata',
    };

    try {
      const startTimeInUTC = meetingDetails?.start_datetime
        ? moment(meetingDetails?.start_datetime)
            .utc()
            .format('YYYY-MM-DD HH:mm')
        : null;

      let body = {
        admin_id: getCurrentAdminId(),
        lead_status: leadScheduledStatusActivity?.status,
        start_time: startTimeInUTC,
      };

      setMetadataForScreeningCallSchedule((prev) => ({
        ...prev,
        admin_id: getCurrentAdminId(),
        lead_status: leadScheduledStatusActivity?.status,
        start_time: startTimeInUTC,
      }));

      const res = await axiosInstance.post(`/api/event`, data);
      if (res.data) {
        setIsSuccess(true);
        setSuccessMessage('Meeting scheduled successfully.');
        handleScheduleMeetingPopUp();
        if (Object.keys(leadScheduledStatusActivity).length !== 0) {
          axiosInstance
            .patch(
              `/api/status_activities_scheduled/${leadScheduledStatusActivity.id}`,
              body
            )
            .then(() => {
              setIsSuccess(true);
              setSuccessMessage('Details updated');
              refreshLeadContext();
              getLeadScheduledActivitiesMetadata();
              setOpenScheduleMeetingPopup(false);
            })
            .catch((error) => {
              console.log(error);
              setHasError(true);
              setErrorMessage(
                'An error occurred while saving screening call details.'
              );
              setOpenScheduleMeetingPopup(false);
            });
        } else {
          updateLeadDetailState('status', 'screening_call_scheduled');
          getLeadScheduledActivitiesMetadata();
          setOpenScheduleMeetingPopup(false);
        }
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage('Could not schedule meeting.');
      console.log('Error scheduling meeting:', error);
      setOpenScheduleMeetingPopup(false);
    }
  };
  return (
    <>
      <Dialog
        open={openScheduleMeetingPopup}
        onClose={handleScheduleMeetingPopUp}
        hideBackdrop={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1rem',
            width: '100%',
          }}
        >
          <Box margin={'0 0 0 0.5rem'}>
            {' '}
            <Typography sx={{ fontWeight: '500', fontSize: '30px' }}>
              Schedule Meeting
            </Typography>
          </Box>

          <Button
            onClick={handleScheduleMeetingPopUp}
            sx={{ color: 'black', flexDirection: 'end' }}
          >
            <CloseRoundedIcon />
          </Button>
        </Box>
        <DialogContent>
          <Box sx={{ mb: '10px' }}>
            <FormControl
              defaultValue=''
              required
              id='meetingName'
              fullWidth
              sx={{ mb: '10px' }}
            >
              <TextField
                type='text'
                name='summary'
                label='Meeting Name'
                onChange={handleMeetingDetailsChange}
                value={meetingDetails.summary}
              />
            </FormControl>
            <FormControl
              defaultValue=''
              required
              id='meetingDetails'
              fullWidth
              sx={{ mb: '10px' }}
            >
              <TextField
                type='text'
                name='description'
                label='Meeting Details'
                onChange={handleMeetingDetailsChange}
                value={meetingDetails.description}
              />
            </FormControl>
            <FormControl
              defaultValue=''
              required
              id='meetingTime'
              fullWidth
              sx={{ mb: '10px' }}
            >
              <TextField
                type='datetime-local'
                name='start_datetime'
                label='Meeting Time'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleMeetingDetailsChange}
                value={meetingDetails.start_datetime}
              />
            </FormControl>
            <FormControl
              defaultValue=''
              required
              id='meetingAttendees'
              fullWidth
            >
              <Box>
                <TextField
                  label='Add Attendee'
                  variant='outlined'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleAddAttendee}
                          aria-label='add'
                          sx={{ ml: 2 }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box mt={1}>
                  {attendees.length > 0 &&
                    attendees.map((email, index) => {
                      const label =
                        typeof email === 'object' ? email.email : email;

                      return (
                        <Chip
                          key={index}
                          label={label}
                          onDelete={handleDelete(label)}
                          style={{ margin: '0.5rem' }}
                        />
                      );
                    })}
                </Box>
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', marginBottom: '10px' }}>
          <Button
            autoFocus
            variant='contained'
            onClick={handleSubmit}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0.5rem 2rem',
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleMeetingPopUp;
