import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  Chip,
  Grid,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { addDays, getTimePeriod, subtractDays } from '../../components/Sidebar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import {
  capitalize,
  getCurrentAdminId,
  getDurationText,
  getFormattedTimestamp,
  currentAdminIsAdmin,
  CopyIcon,
  getTimeZoneFormattedTimestamp,
  splitByUnderscoresAndCapitalize,
  currentAdminIsHr,
} from '../../utils/common';
import constants from '../../utils/constants.js';
import { useCurrentCandidateContext } from '../../components/hrms/CurrentCandidateContext.jsx';
import HRMSProfileDetailsTableView from './HRMSProfileDetailsTableView';
import HRMSCandidateEditForm from './HRMSCandidateEditForm';
import CandidateInteractionsList from './CandidateInteractionsList';
import { getCandidateStatusLable } from '../../utils/hrms/common';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier.jsx';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { useNavigate } from 'react-router-dom';
import {
  hrms_candidate_status,
  hrms_interview_status,
} from '../../utils/hrms/constants.js';
import HrmsNewCandiadteJoiningModal from '../../components/hrms/HrmsJoiningModal.jsx';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCurrAdmin } from 'features/auth/authSelector';
import { fetchAdminsData } from 'features/admins/adminThunk';
import { useDispatch } from 'react-redux';

const Interactions = ({
  selectedRowData,
  openCandidateEditFormToggle,
  logedInDetails,
}) => {
  const [editedFields, setEditedFields] = useState({});
  const [snoozedUntil, setSnoozedUntil] = useState('');
  const [newInteractionNote, setNewInteractionNote] = useState();
  const [interactionInProgress, setInteractionInProgress] = useState(false);
  const [inteactionNotes, setInteractionNotes] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openFeedbackConfirmationModal, setOpenFeedbackConfirmationModal] =
    useState(false);
  const [openCandidateJoiningModal, setOpenCandidateJoiningModal] =
    useState(false);
  const [roles, setRoles] = useState([]);
  const { allAdmins } = useSelector((state) => state.admins);
  const snoozeTimes = ['12 PM', '3 PM', '6 PM', '9 PM'];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getInteractions();
    getCandidateStatus();
  }, [openCandidateEditFormToggle]);

  useEffect(() => {
    fetchAllRoles();
    return () => {};
  }, []);

  function fetchAllRoles() {
    axiosInstance
      .get('/api/roles' + (currentAdminIsHr() ? '?location=candidate' : ''))
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch roles.'
        );
      });
  }

  const {
    candidateData,
    setCandidateData,
    candidateInterviews,
    setCandidateInterviews,
    interactionData,
    setInteractionData,
    getCurrentCandidateAndUpdateContext,
    getCurrentCandidateInterviewsAndUpdateContext,
  } = useCurrentCandidateContext();

  const getInteractions = async () => {
    try {
      const response = await axiosInstance.get(
        base_url() +
          `/api/hrms/hrms_candidates/${selectedRowData.id}/interactions`
      );

      setInteractionNotes(response.data);
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    }
  };

  const getCandidateStatus = async () => {
    try {
      const response = await axiosInstance.get(
        base_url() +
          `/api/hrms/hrms_candidates/${selectedRowData.id}/next_statuses`
      );
      setStatuses(response.data);
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    }
  };

  const updateCandidateStatus = async (status, data = null) => {
    let postData = { status: status };
    if (data) {
      postData = { ...postData, ...data };
    }
    try {
      const response = await axiosInstance.patch(
        base_url() + '/api/hrms/hrms_candidates/' + selectedRowData.id,
        postData
      );

      getCurrentCandidateAndUpdateContext(selectedRowData.id);
      getCandidateStatus();
      dispatch(fetchAdminsData());
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    }
  };

  const updateCandidateUntilSnoozedTime = async (snoozedUntil) => {
    if (!snoozedUntil) {
      setHasError(true);
      setErrorMessage(constants.FOLLOW_UP_TIME_IS_MISSING);
      return;
    }
    const postData = { snoozed_until: snoozedUntil };
    try {
      const response = await axiosInstance.patch(
        base_url() + '/api/hrms/hrms_candidates/' + selectedRowData.id,
        postData
      );
      getCurrentCandidateAndUpdateContext(selectedRowData.id);
      getCandidateStatus();
      setIsSuccess(true);
      setSuccessMessage('Snoozed Until Successfully Updated');
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    }
  };

  const updateInterviewFeedback = async () => {
    try {
      await axiosInstance.patch(
        base_url() + '/api/hrms/hrms_interviews/' + selectedRowData.id,
        {
          feedback_status: 'feedback done',
        }
      );
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    }
  };

  const finalFeedback = async () => {
    try {
      await addInteraction();
      updateInterviewFeedback();

      axiosInstance
        .patch(base_url() + '/api/hrms/hrms_candidates/' + selectedRowData.id, {
          assignee:
            candidateInterviews[candidateInterviews.length - 1]['updated_by'],
          status: candidateInterviews[candidateInterviews.length - 1]['status'],
        })
        .then((res) => {
          setIsSuccess(true);
          setSuccessMessage('Final feedback added sucessfully');
        });
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    } finally {
      navigate(-1);
    }
  };

  const addInteraction = async () => {
    if (!newInteractionNote) return;
    setInteractionInProgress(true);

    const postData = {
      notes: newInteractionNote.trim(),
    };

    try {
      const response = await axiosInstance.post(
        base_url() +
          `/api/hrms/hrms_candidates/${selectedRowData.id}/interactions`,
        postData
      );
      setInteractionNotes((p) => [response.data, ...p]);
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    } finally {
      setInteractionInProgress(false);
      setNewInteractionNote('');
    }
  };
  const checkForNextStatus = (status) => {
    switch (status) {
      case hrms_candidate_status.ROUND_2_DONE:
        return ['manager_round_scheduled', 'salary_finalized'];
      case hrms_candidate_status.MANAGER_ROUND_DONE:
        return ['round_2_scheduled', 'salary_finalized'];
      default:
        return [];
    }
  };

  const handleStatusChange = (status) => {
    const interviewScheduledAndDoneLength = candidateInterviews.length;
    let scheduledStatus, feedbackStatus;
    if (interviewScheduledAndDoneLength > 0) {
      scheduledStatus =
        candidateInterviews[interviewScheduledAndDoneLength - 1]['status'];
      feedbackStatus =
        candidateInterviews[interviewScheduledAndDoneLength - 1][
          'feedback_status'
        ];
    }
    const checkForRound1ScheduledOrNot = () =>
      status === hrms_candidate_status.ROUND_1_SCHEDULED &&
      scheduledStatus !== hrms_candidate_status.ROUND_1_DONE;
    const checkforRound2AndManagerRoundScheduledOrNot = () =>
      interviewScheduledAndDoneLength !== 0 &&
      (status === hrms_candidate_status.ROUND_2_SCHEDULED ||
        status === hrms_candidate_status.MANAGER_ROUND_SCHEDULED) &&
      feedbackStatus !== hrms_interview_status.SCHEDULED;
    const checkForStatusChangeAsPerInterviewScheduled = () =>
      interviewScheduledAndDoneLength !== 0 &&
      checkForNextStatus(scheduledStatus).includes(status) &&
      feedbackStatus === hrms_interview_status.SCHEDULED;
    const checkForInterviewDoneOrNot = () =>
      interviewScheduledAndDoneLength !== 0 &&
      scheduledStatus === status &&
      feedbackStatus === hrms_interview_status.SCHEDULED;

    if (status === hrms_candidate_status.ASSIGNED) {
      setHasError(true);
      setErrorMessage('No assignee for this candidate');
      return;
    }
    if (
      logedInDetails.roles.includes('interviewer') &&
      !logedInDetails.roles.includes('hr')
    ) {
      setHasError(true);
      setErrorMessage('You have not permission for change status.');
      return;
    }
    if (checkForRound1ScheduledOrNot()) {
      setHasError(true);
      setErrorMessage('Please schedule first round interview.');
      return;
    }
    if (checkforRound2AndManagerRoundScheduledOrNot()) {
      setHasError(true);
      setErrorMessage('Please first schedule the interview round.');
      return;
    }
    if (checkForStatusChangeAsPerInterviewScheduled()) {
      setHasError(true);
      setErrorMessage('Please change correct status as per interview round.');
      return;
    }
    if (checkForInterviewDoneOrNot()) {
      setHasError(true);
      setErrorMessage('Interview not completed yet.');
      return;
    }

    if (status === hrms_candidate_status.JOINED) {
      setOpenCandidateJoiningModal(true);
    } else {
      updateCandidateStatus(status);
    }
  };

  const showCandidateStatus = (statuses) => {
    return statuses.map((status, index) => (
      <Tooltip
        onClick={(e) => {
          handleStatusChange(status);
        }}
      >
        {getCandidateStatusLable(status)}
      </Tooltip>
    ));
  };

  const getFeedbackConfirmationModal = () => {
    return (
      <Dialog
        open={openFeedbackConfirmationModal}
        onClose={() => setOpenFeedbackConfirmationModal(false)}
      >
        <DialogContent>
          <DialogContentText>
            This is your Final Feedback. Once submitted, further changes or
            additions won't be possible. Are you sure you're ready to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenFeedbackConfirmationModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              setOpenFeedbackConfirmationModal(false);
              finalFeedback();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const enableButtonIfFeedbackTextEntered = () => {
    return (newInteractionNote || []).length === 0 || interactionInProgress;
  };

  const updateSnoozeUntil = (day, time) => {
    let date = new Date();
    let hours,
      minutes = 0;

    switch (time) {
      case '12 PM':
        hours = 12;
        break;
      case '3 PM':
        hours = 15;
        break;
      case '6 PM':
        hours = 18;
        break;
      case '9 PM':
        hours = 21;
        break;
      case '9 AM':
        hours = 9;
        break;
      default:
        hours = 0;
        break;
    }

    if (day === 'Tomorrow') {
      date.setDate(date.getDate() + 1);
    }

    date.setHours(hours);
    date.setMinutes(minutes);

    setSnoozedUntil(getTimeZoneFormattedTimestamp(date));
  };

  const minDateTime = getTimeZoneFormattedTimestamp(new moment());

  const followUpTimeComponent = () => {
    return (
      <Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontSize: '14px', marginY: 1 }}
        >
          Follow up at
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Today
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {snoozeTimes.map((value, index) => (
                  <Button
                    key={index}
                    size='small'
                    onClick={() => updateSnoozeUntil('Today', value)}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Tomorrow
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {snoozeTimes.map((value, index) => (
                  <Button
                    key={index}
                    size='small'
                    onClick={() => updateSnoozeUntil('Tomorrow', value)}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Custom
              </Typography>
            </Box>
            <Box>
              <TextField
                inputProps={{ min: minDateTime }}
                type='datetime-local'
                name='snoozed_until_1'
                value={snoozedUntil}
                sx={{ width: '100%', backgroundColor: 'white' }}
                onChange={(e) => {
                  const newDate = new moment(e.target.value);
                  setSnoozedUntil(e.target.value); // Store the string value
                  // You might want to handle the newDate further here if needed
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const detailedInteraction = () => {
    if (
      !logedInDetails.roles.includes(constants.INTERVIEWER) &&
      !snoozedUntil
    ) {
      setHasError(true);
      setErrorMessage(constants.FOLLOW_UP_TIME_IS_MISSING);
      return;
    }
    if (logedInDetails.roles.includes(constants.INTERVIEWER) && !snoozedUntil) {
      addInteraction();
      return;
    }

    addInteraction();
    updateCandidateUntilSnoozedTime(snoozedUntil);
  };

  return (
    <Box>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box
        display='flex'
        justifyContent='flex-end'
        padding={2}
        gap={2}
      >
        {showCandidateStatus(statuses)}
      </Box>
      <Stack
        direction='column'
        spacing={2}
        padding={2}
        flex={1}
        sx={{
          backgroundColor: '#f5f6f5',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      >
        <Typography
          sx={{
            marginTop: '0.3rem',
            fontSize: '1.1rem',
          }}
        >
          Detailed Interaction
        </Typography>

        <TextField
          multiline
          rows={3}
          fullWidth
          placeholder='You can highlight text using a colon.'
          value={newInteractionNote}
          onChange={(e) => setNewInteractionNote(e.target.value)}
          sx={{
            backgroundColor: '#ffffff', // White background for TextField
            borderRadius: '4px',
            marginRight: '0.3rem',
          }}
          InputProps={{
            style: { backgroundColor: '#ffffff' }, // Ensuring the input area is white
          }}
        />

        <Box sx={{ marginBottom: '2rem' }}>{followUpTimeComponent()}</Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {logedInDetails.roles.includes('interviewer') && (
            <Stack
              direction='row'
              justifyContent='right'
              paddingRight={2}
              width={200}
            >
              <Button
                onClick={() => setOpenFeedbackConfirmationModal(true)}
                disabled={enableButtonIfFeedbackTextEntered()}
                variant='contained'
                size='small'
                sx={{
                  margin: '0 0.5rem 0 5%',
                  padding: '0.25rem 0.5rem',
                }}
              >
                Final Feedback
              </Button>
            </Stack>
          )}
          <Button
            sx={{ width: '100px' }}
            variant='contained'
            disabled={enableButtonIfFeedbackTextEntered()}
            onClick={() => {
              detailedInteraction();
            }}
          >
            Update
          </Button>
        </Box>
      </Stack>

      {openFeedbackConfirmationModal && getFeedbackConfirmationModal()}
      <Box p={2}>
        <CandidateInteractionsList
          inteactionNotes={inteactionNotes}
          getInteractions={() => getInteractions()}
          selectedRowData={selectedRowData}
        />
      </Box>
      {openCandidateJoiningModal && (
        <HrmsNewCandiadteJoiningModal
          {...{
            openCandidateJoiningModal,
            setOpenCandidateJoiningModal,
            setSuccessMessage,
            setErrorMessage,
            setIsSuccess,
            setHasError,
            selectedRowData,
            updateCandidateStatus,
            candidateData,
            roles,
            allAdmins,
            // setAllAdmins,
          }}
        />
      )}
    </Box>
  );
};

const CandidateDetailView = ({ selectedRowData }) => {
  const [opencandidateEditForm, setOpencandidateEditForm] = useState(false);
  const logedInDetails = getCurrAdmin();

  const openCandidateEditFormToggle = () => {
    setOpencandidateEditForm(!opencandidateEditForm);
  };

  return (
    <Grid
      container
      spacing={1}
      p={2}
      pr={0}
      height={'fit-content'}
      style={{ overflow: 'hidden' }}
    >
      <Grid
        item
        xs={4}
        style={{
          borderRight: '2px solid grey',
          height: '85vh',
          overflow: 'auto',
        }}
      >
        {/* side table */}
        {opencandidateEditForm ? (
          <HRMSCandidateEditForm
            openCandidateEditFormToggle={openCandidateEditFormToggle}
            selectedRowData={selectedRowData}
          />
        ) : (
          <HRMSProfileDetailsTableView
            openCandidateEditFormToggle={openCandidateEditFormToggle}
            selectedRowData={selectedRowData}
            logedInDetails={logedInDetails}
          />
        )}
      </Grid>
      <Grid
        item
        xs={8}
        style={{
          height: '85vh',
          overflow: 'auto',
        }}
      >
        <Interactions
          selectedRowData={selectedRowData}
          openCandidateEditFormToggle={openCandidateEditFormToggle}
          logedInDetails={logedInDetails}
        />
      </Grid>
    </Grid>
  );
};

export default CandidateDetailView;
